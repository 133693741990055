<template>
  <v-container fluid class="ma-0 pa-0">
    <v-progress-circular
      v-show="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <v-container fluid v-show="!loading" class="ma-0 pa-0">
      <v-col v-if="!alarms || alarms.length == 0">Sem alarmes</v-col>

      <v-col>
        <v-container>
          <v-row class="ma-2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @blur="doSearch"
              @keydown.enter="doSearch"
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="alarms"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length.sync="serverItemsLength"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          @update:items-per-page="updateRowsPerPage"
          @update:page="updatePage"
        >
          <template v-slot:item.startedAt="{ item }">
            <span v-date-format="item.startedAt"></span>
          </template>

          <template v-slot:item.manufacturer="{ item }">
            <span>{{ item.manufacturer?.name }}</span>
          </template>

          <template v-slot:item.capacity="{ item }">
            <span v-decimal-format="item.capacity"></span>
          </template>

          <template v-slot:item.dayEnergy="{ item }">
            <span v-decimal-format="item.dayEnergy"></span>
          </template>

          <template v-slot:item.level="{ item }">
            <LevelAlarmChip :status-id="item.level"></LevelAlarmChip>
          </template>
          <template v-slot:item.statusId="{ item }">
            <StatusAlarmChip :status-id="item.statusId"></StatusAlarmChip>
          </template>

          <template v-slot:item.inverter="{ item }">
            <p>{{ item?.inverter?.sn }}</p>
          </template>

          <template v-slot:item.stationName="{ item }">
            <p>{{ item.stationName }}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-container>
  </v-container>
</template>

<script>
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import StatusAlarmChip from "../../components/cards/StatusAlarmChip.vue";
import LevelAlarmChip from "../../components/cards/LevelAlarmChip.vue";
import strapiApi from "../../../../libraries/strapi/strapiApi";
export default {
  name: "AlarmsCard",
  components: {
    Icon,
    StatusAlarmChip,
    LevelAlarmChip,
  },
  props: ["stationId"],
  data() {
    return {
      sortBy: "name",
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 10,
      search: "",

      alarms: [],
      sumaryData: [],
      formats: [`mdi:grid-large`, `mdi:format-list-bulleted`],
      add_usina: false,
      alarm: false,
      loading: true,

      itemsPerPageOptions: [
        { value: 10, title: "10" },
        { value: 25, title: "25" },
        { value: 50, title: "50" },
        { value: 100, title: "100" },
      ],
      headers: [
        { text: "Início", value: "startedAt", align: "start" },
        { text: "Mensagem", value: "message", align: "start" },
        { text: "Nível", value: "level" },
        { text: "Status", value: "statusId" },
        { text: "Usina", value: "stationName", sortable: true },
        { text: "Sn", value: "inverterSn", sortable: true },
      ],
    };
  },
  async mounted() {
    await this.doSearch();
    this.loading = false;
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  methods: {
    goToalarmsPage(alarmsId) {
      if (alarmsId) this.$router.push(`/alarms/${alarmsId}`);
    },
    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      console.log("Do search");
      const { values, pagination } = await strapiApi.alarms.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc,
        this.search,
        this.user?.role?.id,
        this.user?.integrator?.id,
        this.user?.id,
        this.stationId
      );
      this.alarms = values;
      this.serverItemsLength = pagination && pagination.total;
    },
    async getalarmsByUserId() {
      this.alarms = await strapiApi.alarms.getByUserId(this.user?.id);
    },
  },
};
</script>

<style scoped>
#buton {
  margin: 0;
}
</style>
