<template>
  <v-container>
    <p>Integrations</p>

    <v-btn @click="loginGrowatt">Connect</v-btn>
  </v-container>
</template>

<script>
import growattApi from "../../../../libraries/growatt/growattApi";

export default {
  name: "Integrations",
  data() {
    return {
      inverters: [],
    };
  },
  methods: {
    async loginGrowatt() {
      // alert("loginGrowatt");

      var ans = await growattApi.login();
      //console.log(JSON.stringify({ ans }));
    },
  },
};
</script>

<style></style>
