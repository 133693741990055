import inverters from "./inverters";

function getInfoDate() {
  var d = new Date();
  d.setHours(d.getHours() - 3);
  return {
    fullDate: d,
    date: d.toISOString().substring(0, 10),
    hours: d.toISOString().substring(11, 13),
    minutes: d.toISOString().substring(14, 16),
    seconds: d.toISOString().substring(17, 19),
  };
}

window.growattUrl = "https://oss.growatt.com";

var corsolarApi = {
  //inverters,
  async login(user, password) {
    var info = getInfoDate();
    var body = `userName=CCVPB001&password=Sup4002&loginTime=${info.date}+${info.hours}%3A${info.minutes}%3A${info.seconds}&isReadPact=0&changeNotice=0&lang=en&type=1`;
    var url = "https://oss.growatt.com/login";
    var ans = await fetch(url, {
      method: "POST",
      headers: {
        authority: "oss.growatt.com",
        "content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        redirect: "follow",
        "user-agent":
          "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36",
      },
      body,
    }).then((res) => {
      console.log(res);
      return res;
    });

    return ans;
  },
  async test(user, password) {
    var info = getInfoDate();
    var body = `userName=CCVPB001&password=Sup4002&loginTime=${info.date}+${info.hours}%3A${info.minutes}%3A${info.seconds}&isReadPact=0&changeNotice=0&lang=en&type=1`;

    console.log("test");
    var url = "http://localhost:1880/test";
    var ans = await fetch(url, {
      method: "GET",
      headers: {},
      // body,
    }).then((res) => {
      console.log(res);
      return res;
    });
  },
};

export default corsolarApi;
