import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import auth from "./store/auth";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage:
      "https://firebasestorage.googleapis.com/v0/b/hebelsolar.appspot.com/o/sidebar-5.jpg?alt=media&token=bf30b545-edfe-491a-a039-c5953de0bdbe",
    drawer: null,
    alertMsg: "",
    alertShow: false,
    alertType: "success",
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_ALERT_SHOW(state, payload) {
      state.alertShow = payload;
    },
    SET_ALERT_MSG(state, payload) {
      state.alertMsg = payload;
    },
    SET_ALERT_TYPE(state, payload) {
      state.alertType = payload;
    },
    SET_ALERT(state, payload) {
      state.alertMsg = payload.alertMsg;
      state.alertType = payload.alertType;
      state.alertShow = true;

      setTimeout(() => {
        state.alertMsg = "";
        state.alertType = "";
        state.alertShow = false;
      }, 1500);
    },
  },
  actions: {},
  modules: {
    auth,
  },
  plugins: [new VuexPersistence().plugin],
});
