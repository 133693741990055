<template>
  <v-container>
    <!-- <v-dialog v-model="openDialog"> <p>Test</p> </v-dialog> -->
    <ModalUser v-model="openDialog" :user-id="userEditId"></ModalUser>
    <v-row class="pa-4">
      <p>Usinas</p>
      <v-spacer></v-spacer>
      <!-- <v-btn class="primary" @click="openModalUser('new')"> Novo+ </v-btn> -->
    </v-row>

    <v-data-table
      :headers="headers"
      :items="stations"
      item-key="id"
      class="custom-table"
    >
      <template v-slot:item.id="{ item }">
        <v-icon
          color="#08243c"
          @click="openModalUser(item.id)"
          style="font-size: 30px"
          >mdi-magnify</v-icon
        >
      </template>
      <template v-slot:item.createdAt="{ item }">
        <span v-date-format="item.createdAt"></span>
      </template>
      <template v-slot:item.statusId="{ item }">
            <StatusChip :status-id="item.statusId"></StatusChip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import ModalUser from "../../components/modals/ModalUser.vue";
import StatusChip from "../../components/cards/StatusChip.vue";
export default {
  name: "CardStations",
  components: {
    ModalUser,
    StatusChip
  },
  props: ["integratorId"],
  data() {
    return {
      openDialog: false,
      stations: [],
      //   headers:[

      // ],

      userEditId: null,
      headers: [
        // {
        //   text: "Ação",
        //   align: "start",
        //   sortable: false,
        //   value: "id",
        //   width: "20px",
        // },
        {
          text: "Data de criação",
          value: "createdAt",
          width: "20px",
        },
        {
          text: "Id",
          value: "stationId",
          width: "20px",
        },
        {
          text: "Nome",
          value: "name",
          width: "20px",
        },
        { text: "Endereço", value: "address", width: "100px" },
        { text: "Status", value: "statusId" },
      ],
    };
  },
  async mounted() {
    this.stations = await this.getstations();
  },

  methods: {
    async getstations() {
      const stations = await strapiApi.integrators.getStations(
        parseInt(this.integratorId)
      );
      return stations;
    },

    openModalUser(userId) {
      this.userEditId = userId;
      this.openDialog = true;
    },


  },
};
</script>

<style>
.custom-table .v-data-table__wrapper {
  border: none;
}

.custom-table .v-data-table__thead > tr > th:not(:last-child),
.custom-table .v-data-table__tbody > tr > td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}
table td,
th {
  border-left: 1px solid #dddddd;
}
</style>
