<template>
  <div>
    <v-chip v-if="statusId == 1" variant="text" color="#968a2c">
      <span style="color: white">Alarm</span>
    </v-chip>
    <v-chip v-if="statusId == 2" variant="text" color="#888c82">
      <span style="color: white">Comum</span>
    </v-chip>
    <v-chip v-if="statusId == 3" variant="text" color="#a64c4c">
      <span style="color: white">Emergência</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "LevelAlarmChip",
  props: ["statusId"],
};
</script>
