//essa função getAll pega todos os inversores do banco
var inverters = {
    async getAll() {
        const db = window.firebase.firestore();
        let objects = [];
        var ref = db.collection(window.path + "/corsolar/inverters");
        const ans = await ref
        .get()
        .then( (querySnapshot) => {
            objects = querySnapshot.docs.map((x) => x.data());
            return objects; 
        })
        .catch((error) => {
            if (window.debug) console.log("Error getting inverters: ", error)
            return error;
        });
        return ans;
    }
}
export default inverters;