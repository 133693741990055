import functions from "../functions";
import solarman from "./solarman";
import growatt from "./growatt";
import growattServer from "./growattServer";
import soliscloud from "./soliscloud";
import refulog from "./refulog"
import store from "../../../store";
var integrations = {
  refulog,
  solarman,
  growatt,
  growattServer,
  soliscloud,
  async getAll(integratorId) {
    console.log(JSON.stringify({ integratorId }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/integrations?populate[0]=provider`;

    if (integratorId) url += `&filters[integrator][id]=${integratorId}`;

    console.log(JSON.stringify({ message: "get integrations", url }));

    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const integrations = functions.sanitize(res && res.data && res.data);
    //console.log(JSON.stringify({ stations }));

    return integrations;
  },

  async getWithPagination(page, pageSize, sort, sortDesc, filterName) {
    console.log(`sortDesc:${sortDesc}`);
    var textSort = sort ? `&sort[0]=${sort}${sortDesc ? ":desc" : ""}` : "";
    var textFilterName = filterName
      ? `&filters[name][$containsi]=${filterName}`
      : "";
    let url;
    if (pageSize && pageSize !== -1)
      url = `${process.env.VUE_APP_STRAPI_URL}/api/integrations?pagination[page]=${page}&pagination[pageSize]=${pageSize}${textFilterName}${textSort}`;
    else
      url = `${process.env.VUE_APP_STRAPI_URL}/api/integrations?pagination[page]=${page}&pagination[pageSize]=1000${textFilterName}${textSort}`;

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const values = functions.sanitize(res && res.data && res.data);
    //console.log(JSON.stringify({ stations }));
    const pagination = res.meta && res.meta.pagination;
    return { values, pagination };
  },

  async getById(integrationId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/integrations/${integrationId}`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async post(integration, user) {
    //console.log(JSON.stringify({ integration, user }));
    //integrations(filters:{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }} ]   }  ){

    integration.ownerId = user.email;
    integration.createdId = user.email;

    if (integration.cnpj)
      integration.cnpj = integration.cnpj.replace(/\D/g, "");

    if (integration.cpj) integration.cpj = integration.cpj.replace(/\D/g, "");

    //console.log(integration.cnpj);
    //console.log(JSON.stringify({ length: integration.cnpj.length }));
    var body = JSON.stringify({
      data: integration,
    });
    //var url = "http://localhost:1337/graphql";
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/integrations`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async getUsers(integrationId, profileId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[integration][id][$eq]=${integrationId}&populate[0]=*`;

    // var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=${profileId}&filters[$and][1][integration][id][$eq]=${integrationId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[integration][id][$eq]=${integrationId}&populate[0]=*`;

    console.log(JSON.stringify({ url }));

    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getClients(integrationId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=4&filters[$and][1][integration][id][$eq]=${integrationId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[integration][id][$eq]=${integrationId}&populate[0]=*`;

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getStations(integrationId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stations?filters[integrations][id][$eq]=${integrationId}`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    let stations = functions.sanitize(res && res.data && res.data);

    stations = stations.map((el) => ({
      ...el,
      address: `${el.streetAddress ? el.streetAddress + "," : ""}${
        el.neighborhood ? el.neighborhood + "," : ""
      }${el.cityFullName ? el.cityFullName + "," : ""}`,
    }));
    console.log(JSON.stringify({ stations }));

    return stations;
  },

  async getInverters(integrationId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/inverters?filters[integrations][id][$eq]=${integrationId}&populate=*`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    console.log(JSON.stringify({ data: res.data }));
    const inverters = functions.sanitize(res && res.data && res.data);
    console.log(JSON.stringify({ inverters }));

    return inverters;
  },

  async put(value) {
    console.log("PUT" + JSON.stringify({ integration }));
    const integration = JSON.parse(JSON.stringify(value));
    if (integration.cnpj)
      integration.cnpj = integration.cnpj.replace(/\D/g, "");

    if (integration.cpj) integration.cpj = integration.cpj.replace(/\D/g, "");

    //var url = "http://localhost:1337/graphql";
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/integrations/${integration.id}`;
    delete integration["id"];

    var body = JSON.stringify({
      data: integration,
    });

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
};

export default integrations;
