import integrators from "./integrators";
import users from "./users";
import inverters from "./inverters";
import stations from "./stations";
import alarms from "./alarms";
import roleIds from "./rolesIds";
import integrations from "./integrations/integrations";
import providers from "./providers";

var strapiApi = {
  roleIds,
  users,
  integrators,
  inverters,
  stations,
  alarms,
  integrations,
  providers,
};

export default strapiApi;
