<template>
  <v-container fluid>
    <!-- <v-card
      class="my-4 pa-0"
      v-if="(!mobile && station.statusId == 1) || (!mobile && station.statusId == 3)"
    > -->
    <v-card class="my-4 pa-0" v-if="!mobile">
      <v-toolbar color="actionButton" dark>
        <span
          >{{ station.name }} - {{ formatDecimal(station.capacity) }} kWp</span
        >
        <v-spacer></v-spacer>

        <StatusChip :status-id="station.statusId"></StatusChip>
      </v-toolbar>

      <v-img max-height="250" :src="imageUrl"> </v-img>
      <v-card-text>
        <v-card flat>
          <v-row class="" justify="center">
            <v-col cols="12" xs="12" sm="6" md="6" xl="6" align="center">
              <div>
                <div class="text-h4 primary--text">
                  <v-icon class="display-2 primary--text"
                    >mdi-lightning-bolt</v-icon
                  >
                  Energia Total
                </div>
                <div class="pl-4 font-weight-bold display-2">
                  {{ formatDecimal(station.allEnergy) }}
                  MWh
                </div>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" xl="6" align="center">
              <div>
                <div class="text-h4 primary--text">
                  <v-icon class="display-2 primary--text"
                    >mdi-lightning-bolt</v-icon
                  >
                  Energia Mensal
                </div>
                <div class="pl-4 font-weight-bold display-2">
                  {{ formatDecimal(station.monthEnergy) }}
                  kWh
                </div>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" xl="6" align="center">
              <div>
                <div class="text-h4 primary--text">
                  <v-icon class="display-2 primary--text"
                    >mdi-lightning-bolt</v-icon
                  >
                  Energia Anual
                </div>
                <div class="pl-4 font-weight-bold display-2">
                  {{ formatDecimal(station.yearEnergy) }}
                  MWh
                </div>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" xl="6" align="center">
              <div>
                <div class="text-h4 primary--text">
                  <v-icon class="display-2 primary--text"
                    >mdi-lightning-bolt</v-icon
                  >
                  Energia diária
                </div>
                <div class="pl-4 font-weight-bold display-2">
                  {{
                    station.dayEnergy &&
                    station.dayEnergy.toString().replace(".", ",")
                  }}
                  kWh
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="ma-4 pa-4">
            <v-col cols="12">
              <v-btn
                block
                class="actionButton rounded"
                @click="goToStationPage(station && station.id)"
                >Ver Mais</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
    <!-- Offline Station Desktop -->
  </v-container>
</template>

<script>
import StatusChip from "./StatusChip.vue";
export default {
  name: "station-card",
  props: ["station"],

  components: { StatusChip },

  data() {
    return {
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/corsolar-energy.appspot.com/o/solis%2Fpexels-binyamin-mellish-186077.jpg?alt=media&token=a01fd980-5c95-48f6-8f6e-c6a6f12a4a02",
      stationData: [],
      menu: false,
    };
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  async mounted() {
    function transformDate(value) {
      if (value) {
        var date = new Date(value);
        let formatter = date.toLocaleString("zh-cn", {
          timeZone: "Asia/Shanghai",
        });
        var newDate = new Date(formatter);

        return newDate.toLocaleString();
      } else return 0;
    }
    function switchStateAlarme(value) {
      switch (value) {
        case 0:
          return "Pendente";
        case 1:
          return "Resolvido";
        case 2:
          return "Resolvendo";
      }
    }
    function diasDiff(value) {
      if (value) {
        const alarmStartedAt = value;
        var d1 = new Date(alarmStartedAt).toISOString().split("T")[0];
        var d2 = new Date().toISOString().split("T")[0];

        const diffInMs = new Date(d2) - new Date(d1);
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

        return diffInDays;
      } else {
        return 0;
      }
    }
    function switchLevelAlarme(value) {
      switch (value) {
        case "1":
          return "Atenção";
        case "2":
          return "Comum";
        case "3":
          return "Emergência";
      }
    }
    this.stationData = [
      {
        title: "Mensagem do alarme",
        text: this.station.alarmMsg,
      },
      {
        title: "Data de Inicio do alarme",
        text: transformDate(this.station.alarmBeginTime),
      },
      {
        title: "Duração do Alarme",
        text: diasDiff(this.station.alarmBeginTime) + " dias",
      },
      {
        title: "Estado do Alarme",
        text: switchStateAlarme(this.station.alarmState),
      },
      {
        title: "Nivel do Alarme",
        text: switchLevelAlarme(this.station.alarmLevel),
      },
    ];
  },
  created() {},
  //loadedCity
  watch: {},
  methods: {
    formatDecimal(value) {
      var d = Math.round(value * 100) / 100;
      var r = new Intl.NumberFormat("pt-BR", {
        //  currency: "BRL",
        // style: "currency",
        minimumFractionDigits: 2,
      }).format(d || 0);

      return r;
    },

    goToStationPage(stationId) {
      if (stationId) this.$router.push(`/station/${stationId}`);
    },
  },
};
</script>

<style scoped>
vue-simple-suggest {
  border-style: solid;
  border-color: lightgray;
  color: gray;

  border-radius: 40px;
  height: 50px;
  width: 90%;
  background-color: transparent;

  padding-left: 15px;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  margin: 2%;
  margin-left: 20px;

  text-indent: 7.5%; /* "Alinhar" o texto do select*/
}
#status {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  float: left;
}
#statusMobile {
  display: block;
  width: 13px;
  height: 12px;
  border-radius: 100%;
  float: left;
}
#statusMobileAlarm {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  float: left;
}
</style>
