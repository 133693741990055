var dateFunctions = {
    diasDiff(value){
        const inicioAlarme = value
        var d1 = new Date(inicioAlarme).toISOString().split('T')[0]
        var d2 = new Date().toISOString().split('T')[0]
        
        const diffInMs = new Date(d2) - new Date(d1)
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  
        return diffInDays
    },
    transformDate(value){
        var date = new Date(value)
        let formatter = date.toLocaleString('zh-cn',{timeZone:"Asia/Shanghai"})
        var newDate = new Date(formatter)
  
  
        return newDate.toLocaleString()
    },

}
export default dateFunctions