<template>
  <v-container fluid style="margin: 0px">
    <v-progress-circular
      v-show="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <v-container fluid v-show="!loading">
      <div>
        <StationsSumaryCard
          :data="sumaryData"
          :counts="counts"
        ></StationsSumaryCard>
      </div>

      <v-col v-if="!stations || stations.length == 0"
        >Sem estações disponíveis</v-col
      >
      <v-col
        v-for="(station, indexStation) in stations"
        :key="indexStation"
        class="ma-0 pa-0"
        v-if="!tableView"
      >
        <station-card class="ma-0 pa-0" :station="station"></station-card>
      </v-col>

      <v-col v-if="tableView">
        <v-container>
          <v-row class="ma-2">
            <v-col
            cols="12"
            sm="3"
            md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @blur="doSearch"
              @keydown.enter="doSearch"
            ></v-text-field>
            </v-col>
            <v-col>
                <v-switch
                v-model="searchIntegrator"
                label="Busca por Integrador"
                >
              </v-switch>
            </v-col>
            <v-col>
              <!-- <v-btn color="green" dark v-on:click="resumeStations">
                <v-icon color="gray">mdi-microsoft-excel</v-icon> Exportar
              </v-btn> -->
              <v-menu offset-y ffset-x :close-on-click="true" :close-on-content-click="false">
              <v-spacer></v-spacer>
              <template v-slot:activator="{ on, attrs }" v-if="user.role.name == 'Admin'">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="exportMenu = true">
                <v-icon color="gray">mdi-microsoft-excel</v-icon>

              </v-btn>
              </template>
              <template v-slot:activator="{ on, attrs }" v-else>
              <v-btn color="primary" dark v-on:click="resumeStations">
                <v-icon color="gray">mdi-microsoft-excel</v-icon>

              </v-btn>
              </template>
              <v-container style="background-color: white;" v-show="exportMenu">
                <v-menu
                v-model="exportMenu"
                ref="exportMenu"
                transition="scale-transition"
                :close-on-content-click="false"
                :close-on-click="false"
                :return-value.sync="date"
                offset-y
                max-width="300px"
                min-width="auto"

              >
                <template v-slot:activator="{ on, attrs }">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Selecione o mês desejado"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"

                    ></v-text-field>
                    <v-btn color="green" v-on:click="resumeStations"
                    :loading="exportLoading">Exportar</v-btn>
                  </template>
                  <v-date-picker
                    v-model="date"
                    type="month"
                    scrollable
                    locale="pt-BR"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                  </v-dialog>
              </template>
              </v-menu>
              </v-container>
              <!-- <v-list style="width: 200px">

                Visíveis
              </v-list> -->
            </v-menu>
            </v-col>
            <v-col>
            <v-menu offset-y offset-x>
              <v-spacer></v-spacer>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon color="gray">mdi-cog</v-icon> Exibir
                </v-btn>
              </template>
              <v-container style="background-color: white; right: 250px">
                <v-switch
                  class="px-5"
                  v-model="showOnlyVisible"
                  flat
                  :label="`Somente visíveis`"
                ></v-switch>
              </v-container>
              <!-- <v-list style="width: 200px">

                Visíveis
              </v-list> -->
            </v-menu>
          </v-col>
          </v-row>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="stations"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length.sync="serverItemsLength"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          @update:items-per-page="updateRowsPerPage"
          @update:page="updatePage"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2"></v-icon>
          </template>

          <template v-slot:item.manufacturer="{ item }">
            <span>{{ item.manufacturer?.name }}</span>
          </template>

          <template v-slot:item.id="{ item }">
            <v-icon
              color="#08243c"
              style="font-size: 30px"
              @click="goToStationPage(item.id)"
              >mdi-magnify</v-icon
            >
          </template>

          <template v-slot:item.hidden="{ item }">
            <v-icon
              v-if="item.hidden"
              @click="toggleHidden(item)"
              color="#08243c"
              style="font-size: 18px"
              >mdi-eye-off</v-icon
            >
            <v-icon
              v-else
              @click="toggleHidden(item)"
              color="#08243c"
              style="font-size: 18px"
              >mdi-eye</v-icon
            >
          </template>

          <template v-slot:item.capacity="{ item }">
            <span v-decimal-format="item.capacity"></span>
          </template>

          <template v-slot:item.dayEnergy="{ item }">
            <span v-decimal-format="item.dayEnergy"></span>
          </template>
          <template v-slot:item.statusId="{ item }">
            <StatusChip :status-id="item.statusId"></StatusChip>
          </template>
        </v-data-table>
      </v-col>
    </v-container>
  </v-container>
</template>

<script>
import StationCard from "../../components/cards/StationCard.vue";
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import StationsSumaryCard from "../../components/cards/StationsSumaryCard.vue";
import StationTable from "../../components/cards/StationTable.vue";
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import StatusChip from "../../components/cards/StatusChip.vue";

import strapiApi from "../../../../libraries/strapi/strapiApi";
export default {
  name: "Stations",
  components: {
    StationCard,
    StationsSumaryCard,
    StationTable,
    Icon,
    StatusChip,
  },
  data() {
    return {
      sortBy: "name",
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 10,
      search: "",
      date:"",
      menu:"",
      modal:"",
      exportLoading: false,
      exportMenu: false,
      stations: [],
      sumaryData: [],
      formats: [`mdi:grid-large`, `mdi:format-list-bulleted`],
      add_usina: false,
      alarm: false,
      loading: true,
      showOnlyVisible: true,
      searchIntegrator: false,

      itemsPerPageOptions: [
        { value: 10, title: "10" },
        { value: 25, title: "25" },
        { value: 50, title: "50" },
        { value: 100, title: "100" },
      ],
      //headers: ,
      ans: {},
      counts: {
        //1: Normal, 2: Offline e 3: Alarme
        1: {
          name: "Normal",
          count: 0,
        },
        2: {
          name: "Offline",
          count: 0,
        },
        3: {
          name: "Alarme",
          count: 0,
        },
      },
    };
  },
  async mounted() {
    await corsolarApi.userLogs.verifyIfExistLog(this.user, this.$router);

    if (
      this.user?.role?.id == strapiApi.roleIds.engineer ||
      this.user?.role?.id == strapiApi.roleIds.admin ||
      this.user?.role?.id == strapiApi.roleIds.integrator
    ) {
      await this.doSearch();
      await this.loadSummary();
    }
    if (this.user?.role?.id == strapiApi.roleIds.client) {
      await this.getStationsByUserId();
      await this.loadSummary();
    }

    if (this.user?.role?.id == strapiApi.roleIds.integrator) {
      //Hide station
    }

    this.loading = false;
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      token: (state) => state.auth.token,
    }),

    tableView() {
      return this.user?.role?.id !== strapiApi.roleIds.client;
    },

    headers() {
      let items = [
        {
          text: "Detalhes",
          align: "start",
          sortable: false,
          value: "id",
          width: "20px",
        },
        {
          text: "Não mostrar",
          align: "start",
          sortable: false,
          value: "hidden",
          width: "20px",
        },
        { text: "Nome", value: "name", align: "start" },
        {
          text: "Fornecedor",
          value: "manufacturer",
          align: "manufacturer",
          sortable: false,
        },
        // { text: "Cidade", value: "cityFullName" },
        { text: "Localização", value: "address", sortable: false },
        { text: "Status", value: "statusId" },
        { text: "Potência instalada (kWp)", value: "capacity" },
        { text: "Energia diária", value: "dayEnergy" },
      ];

      if (this.user?.role?.name == "Admin" || this.user?.role?.name == "Engineer"){
        items.push({
          text: "Integrador",
          value: "integratorsNames",
          align: "integratorsNames",
          align:"start",
        })
      }
      // if (this.user?.role?.id !== strapiApi.roleIds.integrator) {
      //   items = items.filter((el) => el.value !== "hide");
      // }
      return items;
    },
  },

  watch: {
    async showOnlyVisible(val, oldVal) {
      if (val === oldVal) return;

      await this.doSearch();
      await this.loadSummary();
    },
  },

  methods: {
    async loadSummary() {
      let ans = await strapiApi.stations.getSummary(
        this.user?.id,
        this.showOnlyVisible
      );
      this.ans = ans;

      for (let c = 1; c <= 3; c++) {
        let count = ans && ans?.counts?.find((el) => el.status_id == c);
        this.counts[c].count = count?.count ? parseInt(count?.count) : 0;
      }
      // dados dos cards, os mesmos que tem em 'Station.vue'.  Estão no componet StationsSumaryCard
      this.sumaryData = [
        {
          icon: "mdi-speedometer",
          text: "Capacidade instalada",
          value: ans?.sum_capacity,
          valueStr: `kWp`,
          additionalInfo: "",
          additionalValue: ``,
          additionalValueStr: ``,
        },
        {
          icon: "mdi-lightning-bolt",
          text: "Geração hoje",
          value: ans?.sum_day_energy,
          valueStr: `kWh`,
          additionalInfo: "",
          additionalValue: ``,
        },
        {
          icon: "mdi-calendar",
          text: "Geração no mês",
          value: ans?.sum_month_energy / 1000,
          valueStr: `MWh`,
          additionalInfo: "",
          additionalValue: ``,
        },
        {
          icon: "mdi-solar-panel-large",
          text: "Geração no ano",
          value: ans?.sum_year_energy / 1000,
          valueStr: `MWh`,
          additionalInfo: "",
          additionalValue: ``,
        },
      ];
    },

    async resumeStations(){

      this.exportLoading = true
      const resume = await strapiApi.stations.resumeCsv(this.date);
      // this.$refs.exportMenu.save()
      this.date = ""
      this.exportLoading = false
      this.exportMenu = false

      return
    },

    async toggleHidden(station) {
      await strapiApi.stations.toggleHidden(station);

      this.doSearch();
    },

    goToStationPage(stationId) {
      if (stationId) this.$router.push(`/station/${stationId}`);
    },
    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {

      console.log(`*********** INTEGRATORID: ${this.integratorId}`)

      const { values, pagination } = await strapiApi.stations.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc,
        this.search,
        this.user?.role?.id == strapiApi.roleIds.integrator &&
        this.user?.integrator?.id,
        this.showOnlyVisible,
        this.searchIntegrator
      );

      console.log(values)

      this.stations = values.map((station) => {
        station.integratorsNames = ''

        if(station.integrators.length == 1) {
          station.integratorsNames = station.integrators[0].name
        }else{
          station.integratorsNames += station.integrators.map((integrator) => {

            if(station.integrators[0].name == integrator.name){
              return `${integrator.name}`
            }else{
              return ` ${integrator.name}`
            }

          })
        }
        return station
      });
      console.log(JSON.stringify({ pagination }));
      this.serverItemsLength = pagination && pagination.total;
    },
    async getStationsByUserId() {
      this.stations = await strapiApi.stations.getByUserId(this.user?.id);
    },
  },
};
</script>

<style scoped>
#buton {
  margin: 0;
}
</style>
