<template>
  <div>
    <!-- <v-btn @click="test"> Test</v-btn> -->
    <v-card class="mx-auto" max-width="800">
      <v-toolbar class="actionButton" dark> Configurações de Conta</v-toolbar>

      <v-container v-if="userEdit" class="pa-4">
        <v-text-field v-model="userEdit.username" label="Nome:"> </v-text-field>
        <v-text-field v-model="userEdit.email" label="Email:" disabled>
        </v-text-field>
        <v-checkbox
          v-if="userEdit"
          v-model="userEdit.reportSubscription"
          label="Desejo receber um relatório mensal de geração por email"
        >
        </v-checkbox>
        <v-btn color="actionButton" @click="saveUserData()" :disabled="saving"
          ><span v-show="!saving && !saveAlert">Salvar</span>
          <span v-show="saveAlert"
            >Salvo <v-icon color="green">mdi-check</v-icon></span
          >
          <v-progress-circular
            v-show="saving"
            indeterminate
            color="white"
          ></v-progress-circular
        ></v-btn>

        <v-alert
          type="success"
          icon="mdi-check"
          class="pa-2"
          v-model="saveAlert"
        >
          Usuário atualizado com sucesso
        </v-alert>
        <v-alert
          type="error"
          icon="mdi-alert"
          class="pa-2"
          v-model="errorAlert"
          >{{ errorMsg }}</v-alert
        >
        <br />
        <br />
        <v-card>
          <v-toolbar class="primary" dark> Alterar senha:</v-toolbar>
          <v-card-text>
            <!-- <v-text-field
              label="Senha atual"
              type="password"
              v-model="currentPassword"
            >
            </v-text-field> -->
            <v-password-field v-model="currentPassword" label="Senha atual">
            </v-password-field>

            <!-- <v-text-field
              label="Nova senha"
              type="password"
              v-model="newPassword"
            >
            </v-text-field> -->

            <v-password-field v-model="newPassword" label="Nova senha">
            </v-password-field>
            <p
              class="red--text"
              v-if="
                newPassword != '' &&
                confirmNewPassword != '' &&
                newPassword != confirmNewPassword
              "
            >
              As senhas precisam ser iguais!
            </p>
            <p
              class="red--text"
              v-if="newPassword != '' && newPassword.length < 6"
            >
              A senha precisa ter mais de 6 caracteres
            </p>

            <!-- <v-text-field
              label="Confirmar nova senha"
              type="password"
              v-model="confirmNewPassword"
            >
            </v-text-field> -->

            <v-password-field
              v-model="confirmNewPassword"
              label="Confirmar nova senha"
            >
            </v-password-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="actionButton"
              :disabled="updatingPassword"
              @click="
                changePassword(currentPassword, newPassword, confirmNewPassword)
              "
              ><span v-show="!updatingPassword && !successAlert">Salvar</span>
              <!-- <span v-show="successAlert"
                >Senha atualizda com sucesso <v-icon color="green">mdi-check</v-icon></span
              > -->
              <v-progress-circular
                v-show="updatingPassword"
                indeterminate
                color="white"
              ></v-progress-circular
            ></v-btn>
          </v-card-actions>
          <v-alert
            type="success"
            icon="mdi-check"
            class="pa-2"
            v-model="successAlert"
          >
            Senha atualizada com sucesso!
          </v-alert>
          <v-alert
            type="error"
            icon="mdi-alert"
            class="pa-2"
            v-model="errorAlert2"
            >{{ errorMsg2 }}</v-alert
          >
        </v-card>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import VPasswordField from "../../components/fields/VPasswordField.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "User",
  components: {
    VPasswordField,
  },
  data() {
    return {
      userStations: [],
      usinaSelecionada: null,
      newPassword: "",
      confirmNewPassword: "",
      currentPassword: null,
      userObj: null,
      userEdit: null,
      credentials: null,
      successAlert: false,
      saveAlert: false,
      errorAlert: false,
      errorAlert2: false,
      errorMsg: "",
      errorMsg2: "",
      stations: [],
      saving: false,
      updatingPassword: false,
    };
  },
  async mounted() {
    //log creation
    await corsolarApi.userLogs.verifyIfExistLog(this.user, this.$router);

    this.userEdit = Object.assign({}, this.user);
  },
  methods: {
    ...mapMutations({
      setAlert: "SET_ALERT",
    }),
    closeAlerts() {
      this.saveAlert = false;
      this.errorAlert = false;
      this.errorAlert2 = false;
      this.successAlert = false;
    },
    async saveUserData() {
      this.saving = true;
      let ans = await strapiApi.users.update(this.userEdit);
      this.saving = false;
      console.log(JSON.stringify({ ans }));
      await this.openAlertsSave(ans);
    },

    async openAlertsSave(ans) {
      if (ans.id) {
        this.$store.commit("SET_USER", ans);
        this.saveAlert = true;
      } else if (ans?.error) {
        this.errorAlert = true;
        this.errorMsg = ans?.error?.message;
      }
      setTimeout(this.closeAlerts, 2000);
    },
    goToStation(stationId) {
      this.$router.push(`/station/${stationId}`);
    },

    async changePassword(currentPassword, newPassword, confirmNewPassword) {
      if (currentPassword == "") {
        this.setAlert({
          alertMsg: "Necessário senha atual para atualizar informações",
          alertType: "error",
        });

        return;
      } else if (
        newPassword == "" ||
        newPassword.length < 6 ||
        newPassword !== confirmNewPassword
      ) {
        this.setAlert({
          alertMsg: "Verifique sua nova senha e tente novamente!",
          alertType: "error",
        });
        return;
      }
      this.updatingPassword = true;

      const ans = await strapiApi.users.changePassword(
        currentPassword,
        newPassword,
        confirmNewPassword
      );

      this.openAlerts(ans);
      this.updatingPassword = false;
    },
    openAlerts(condition) {
      if (condition.jwt) {
        this.currentPassword = "";
        this.newPassword = "";
        this.confirmNewPassword = "";
        this.successAlert = true;
      } else if (condition?.error) {
        this.errorAlert2 = true;
        this.errorMsg2 = condition?.error?.message;
      }
      setTimeout(this.closeAlerts, 2000);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
};
</script>

<style scoped></style>
