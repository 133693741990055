<!-- essa página é a de gestão de usuarios, o layout está em um componente chamado userDataTable -->
<template>
  <v-container>
    <usersDataTable
      :users="users"
      :StationsSelected="StationsSelected"
      :userStations="userStations"
      :stations="stations"
      :exibe="exibe"
      :exibeStations="exibeStations"
      :headers="headers"
      :headersStations="headersStations"
      :headersStationsEdited="headersStationsEdited"
      :dialogViewStations="dialogViewStations"
      :dialogEditUser="dialogEditUser"
      :dialogEditStations="dialogEditStations"
      :lista_de_status="lista_de_status"
      :editUser="editUser"
      :viewStations="viewStations"
      :editStations="editStations"
      :getColor="getColor"
      :closeDialog="closeDialog"
      :goToPage="goToPage"
      :goToStation="goToStation"
      :dataTable="dataTable"
      :lista_de_accessLevel="lista_de_accessLevel"
      :dialogueDelete="dialogueDelete"
      :dialogDeleteUser="dialogDeleteUser"
      :selected="selected"
    />
  </v-container>
</template>
<script>
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import usersDataTable from "../../components/cards/usersDataTable.vue";
import { mapState } from "vuex";
export default {
  name: "managementScreen",
  components: {
    usersDataTable,
  },
  data() {
    return {
      users: [],
      selected: [],
      StationsSelected: [],
      userStations: [],
      // inverters: [],
      stations: [],
      exibe: "",
      exibeStations: [],
      users: [],
      dataTable: [],
      // esse headear são as colunas da tabela de usuarios
      headers: [
        { text: "Nome", align: "start", value: "name" },
        { text: "Usina", value: "Stations", sortable: false },
        { text: "E-mail", value: "email" },
        { text: "Criado", value: "createdAtStr" },
        { text: "Permissão", value: "accessLevel" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "config", sortable: false },
      ],
      // esse headersStations possui as colunas da tabela station
      headersStations: [
        { text: "Estação", value: "stationName" },
        { text: "Número do serial", value: "sn" },
      ],
      // o headersStationsEdited possui as colunas da tabela de stations selecionados, quando se entra na parte de gestão de usinas
      headersStationsEdited: [
        { text: "Estação", value: "sn" },
        { text: "Excluir", value: "delete" },
      ],
      dialogViewStations: false,
      dialogEditUser: false,
      dialogEditStations: false,
      dialogDeleteUser: false,
      lista_de_status: ["Ativo", "Aguardando Aprovação", "Desativado"],
      lista_de_accessLevel: ["Adm", "Engenharia", "Cliente"],
    };
  },
  async mounted() {
    this.users = await corsolarApi.users.getAll();
    //log creation/update
    await corsolarApi.userLogs.verifyIfExistLog(this.user, this.$router);

    // puxando os stations, users e inverters do banco
    // this.users = await corsolarApi.users.getAll();
    var stations = await corsolarApi.stations.getAll();

    //Ajuste para apresentação

    this.stations = stations.filter((el) => el.stationName);
    //this.stations = stations;
    // this.inverters = await corsolarApi.inverters.getAll();
  },
  methods: {
    //abre a caixa de dialogo onde é feita as alterações do mesmo como email, status, acesso, etc.
    editUser(user) {
      this.exibe = user;
      this.dialogEditUser = true;
    },
    // abre a caixa de dialogo onde é exibido todos os stations associados ao usuarios, ao mesmo tempo que define qual são esses stations
    async viewStations(item) {
      this.exibeStations = [];
      this.exibe = item;
      this.dialogViewStations = true;

      // this.userEdit = Object.assign({}, this.user);
      // console.log(this.userEdit)
      // // if (!this.userEdit.subscriptions)
      // //   this.userEdit.subscriptions = { monthReport: false };

      const userObj = await corsolarApi.users.getByUid(this.exibe.uid);
      this.userStations = Object.values(userObj.stations);

      var stations = [];
      for (var a = 0; a < this.userStations.length; a++) {
        var stationId = this.userStations[a];
        var station = await corsolarApi.stations.getFromId(stationId);
        stations.push(station);
        this.exibeStations.push(station);
      }

      //this.stations = stations;
    },
    //abre a caixa de dialogo no qual são feitas as associações de stations
    async editStations(item) {
      console.log(item);
      this.exibe = item;
      this.dialogEditStations = true;
      const userObj = await corsolarApi.users.getByUid(this.exibe.uid);
      this.userStations = Object.values(userObj.stations);
      for (let i = 0; i < this.userStations.length; i++) {
        let stationId = this.userStations[i];
        let station = await corsolarApi.stations.getFromId(stationId);
        this.selected.push(station);
      }
    },
    getColor(status) {
      if (status === "Ativo") return "actionButton";
      else if (status === "Aguardando Aprovação") return "#FB8C00";
      else return "red";
    },
    closeDialog() {
      this.dialogEditUser = false;
      this.dialogViewStations = false;
      this.dialogEditStations = false;
      this.dialogDeleteUser = false;
      this.selected = [];
    },
    goToPage() {
      this.$router.push(`import`);
    },
    goToStation(station) {
      if (station) this.$router.push(`/station/${station.providerStationId}`);
    },
    dialogueDelete(item) {
      this.exibe = item;
      this.dialogDeleteUser = true;
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>
