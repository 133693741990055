import stations from "./stations";
import users from "./users";
import updateStations from "./updateStations";
import logs from "./logs";
import inverters from "./inverters";
import logIn from "./logIn";
import alarms from "./alarms";
import dateFunctions from "./dateFunctions";
import userLogs from "./userLogs";

var corsolarApi = {
  stations,
  logs,
  users,
  updateStations,
  inverters,
  logIn,
  alarms,
  dateFunctions,
  userLogs,
};

export default corsolarApi;
