<template>
  <div>
    <v-chip v-if="statusId == 1" variant="text" color="#41d068">
      <span style="color: white">Normal</span>
    </v-chip>
    <v-chip v-if="statusId == 2" variant="text" color="#f76558">
      <span style="color: white">Offline</span>
    </v-chip>
    <v-chip v-if="statusId == 3" variant="text" color="#f8b51e">
      <span style="color: white">Alarme</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "StatusChip",
  props: ["statusId"],
};
</script>
