<template>
  <v-container fluid>
    <v-container v-if="!station && !loading" fluid>
      <h3>Nenhuma estação encontrada</h3>
    </v-container>
    <v-container v-else>
      <Menu :station="station" :mobile="mobile"></Menu>
      <v-container fluid v-show="loading">
        <v-row justify="center">
          <v-col cols="12" align="center">
            <v-progress-circular
              v-show="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
      <!-- Informações  -->

      <div v-show="!loading">
        <StationsSumaryCard :data="sumaryData"></StationsSumaryCard>
      </div>

      <!-- Gráfico -------- -->
      <v-row justify="end" v-show="!loading">
        <v-col
          cols="12"
          xs="4"
          sm="6"
          md="3"
          xl="3"
          v-if="periodSelected?.enablebDatePicker"
        >
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                label="Selecione a data "
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                :disabled="disableDatePicker"
                color="actionButton"
                event-color="actionButton"
              ></v-text-field>
            </template>
            <v-card class="ma-0 pa-0">
              <v-date-picker
                v-model="dateFilter"
                :type="periodSelected?.typeDatePicker"
                no-title
                @input="menu = false"
                reactive
                scrollable
                color="actionButton"
                @change="changeDate"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
              ></v-date-picker>
            </v-card>
          </v-menu>
        </v-col>
        <v-col class="text-right" cols="12" xs="4" sm="6" md="3" xl="2">
          <v-btn-toggle v-model="toggle_exclusive">
            <v-btn
              v-for="(period, index) in periods"
              :key="period.value"
              :item="index"
              color="actionButton"
              small
              outlined
              @click="changePeriod(period)"
              >{{ period.text }}</v-btn
            >
          </v-btn-toggle>
        </v-col>
      </v-row>

      <!-- BarChart -->
      <v-row v-show="!loadingData">
        <v-col v-if="periodSelected?.value === 'day'">
          <v-card class="pa-3">
            <LineChart :chart-data="dataCollection" :chart-options="options" />
          </v-card>
        </v-col>
        <v-col
          v-if="
            periodSelected?.value === 'month' ||
            periodSelected?.value === 'year' ||
            periodSelected?.value === 'all'
          "
        >
          <v-card class="pa-3">
            <!-- <chart-card
              :dataCollection="dataCollectionBarChart"
              :options="options"
            >
            </chart-card> -->

            <BarChart :chart-data="dataCollection" :chart-options="options" />
          </v-card>
        </v-col>
        <!-- <v-card v-if="loadingData">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-card> -->
      </v-row>

      <v-row v-show="loadingData">
        <v-card style="width: 100%; height: 450px; justify-content: center">
          <br />
          <v-row>
            <v-spacer></v-spacer>
            <v-progress-circular
              style="margin: auto"
              indeterminate
              color="primary"
            ></v-progress-circular>

            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
    <v-container>
      <v-tabs color="primary accent-4">
        <v-tab>Alarmes</v-tab>
        <v-tab>Dispositivos</v-tab>

        <v-tab-item>
          <alarms-card :station-id="stationId"> </alarms-card>
        </v-tab-item>

        <v-tab-item>
          <inverters-card :station-id="stationId"></inverters-card>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </v-container>
</template>

<script>
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import ChartCard from "../../components/charts/Barchart.vue";
import BarChart from "../../components/charts/BarChart2.vue";
import StationsSumaryCard from "../../components/cards/StationsSumaryCard.vue";
import LineChart from "../../components/charts/LineChart2.vue";
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";

import Menu from "../../components/core/menu.vue";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import AlarmsCard from "../../components/cards/AlarmsCard.vue";
import InvertersCard from "../../components/cards/InvertersCard.vue";

export default {
  name: "Station",
  props: ["stationId"],
  components: {
    ChartCard,
    StationsSumaryCard,
    LineChart,
    Menu,
    Icon,
    AlarmsCard,
    InvertersCard,
    BarChart,
  },
  data: (vm) => ({
    disableDatePicker: false,
    typeDatePicker: "day",
    menu: false,
    dateFilter: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    loading: true,
    loadingData: false,

    periods: [
      {
        value: "day",
        text: "Dia",
        enablebDatePicker: true,
        typeDatePicker: "date",
      },
      {
        value: "month",
        text: "Mês",
        enablebDatePicker: true,
        typeDatePicker: "month",
      },
      {
        value: "year",
        text: "Ano",
        enablebDatePicker: true,
        typeDatePicker: "month",
      },
      {
        value: "all",
        text: "Global",
        enablebDatePicker: false,
        typeDatePicker: "month",
      },

      // month: "Mês",
      // year: "Ano",
      // all: "Global",
    ],
    toggle_exclusive: 0, //ChartCard
    amountArrayBar: [],
    labelsArrayBar: [],
    amountArrayLine: [],
    labelsArrayLine: [],
    dataCollection: {},
    station: null,
    sumaryData: [],
    dayLogs: [],
    monthLogs: [],
    yearLogs: [],
    allLogs: [],
    amountArray: [],
    periodSelected: {
      value: "day",
      text: "Dia",
      enablebDatePicker: true,
      typeDatePicker: "date",
    },
    datalogs: [],
    dataCollectionBarChart: {
      datasets: [
        {
          label: "Energia kW",
          fill: false,
          data: [],
        },
      ],
    },
    // options: {
    //   responsive: false,
    //   maintainAspectRatio: false,
    // },
    options: {
      pointStyle: "none",
      elements: {
        point: {
          radius: 0,
        },
      },
      animation: {
        duration: 0,
      },
      responsive: true,
      maintainAspectRatio: false,
      bezierCurve: false,
      interaction: {
        intersect: false,
        mode: "index",
      },

      scales: {
        x: {
          type: "time", // Use the 'time' scale for the x-axis
          min: 0,
          max: 0,
          time: {
            unit: "minute", // Set the time unit to minutes
          },
          // title: {
          //   display: true,
          //   text: "Hora",
          // },
          grid: {
            display: false,
          },
        },
        y: {
          title: {
            display: true,
            text: "Potência (kW)",
          },
          grid: {
            display: false,
          },
        },
      },
    },
  }),
  async mounted() {
    await corsolarApi.userLogs.verifyIfExistLog(this.user, this.$router);

    this.station = await strapiApi.stations.getById(this.stationId);
    if (!this.station) {
      this.loading = false;
      return;
    }

    if (!this.station.providerStationId) {
      const parts = this.station?.stationId.split("_");
      if (parts?.[1]) {
        this.station.providerStationId = parts?.[1];
      }
    }

    //
    // esses dados são dos cards que se encontram no topo da página, eles estão localizados nos componentes com o nome de 'StationsSumaryCard'

    this.sumaryData = [
      {
        icon: "mdi-speedometer",
        text: "Capacidade instalada",
        value: `${this.station.capacity}`,
        valueStr: `kWp`,
        additionalInfo: `-`,
        additionalValue: `-`,
      },
      {
        icon: "mdi-lightning-bolt",
        text: "Geração hoje",
        value: `${this.station.dayEnergy}`,
        valueStr: `kWh`,
        additionalInfo: "Lucro Diário:",
        additionalValue: this.station.dayEnergy * this.station.energyPrice,
      },
      {
        icon: "mdi-calendar",
        text: "Geração no mês",
        value: `${this.station.monthEnergy}`,
        valueStr: `kWh`,
        additionalInfo: "Lucro Mensal:",
        additionalValue: this.station.monthEnergy * this.station.energyPrice,
      },
      {
        icon: "mdi-solar-panel-large",
        text: "Geração total",
        value: `${this.station.allEnergy}`,
        valueStr: `kWh`,
        additionalInfo: "Lucro Total:",
        additionalValue: this.station.allEnergy * this.station.energyPrice,
      },
    ];

    this.loading = false;
    this.loadChartData(this.periodSelected); // executa ambas para que quando a página for iniciada já exibir um gráfico com informações
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.dateFilter);
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  methods: {
    async getLogs(siteId, periodValue, startDate, endDate) {
      let logs = await corsolarApi.logs.get(
        siteId,
        periodValue,
        startDate.getTime(),
        endDate.getTime()
      );

      return logs;
    },
    // nessa função é entendido qual botão foi clicado para filtrar o grafico, lembrando que ao iniciar essa página, essa função é executada com o parametro 'day'
    async loadChartData(period) {
      this.periodSelected = period;

      this.loadingData = true;

      var date;
      let dataCollection;
      let options;
      if (this.dateFilter) date = new Date(this.dateFilter + "T03:00:00");
      else date = new Date(Date.now());

      let startDate = new Date(date.getTime());
      startDate.setHours(0);
      startDate.setMinutes(0);

      let endDate;

      this.typeDatePicker = "date";
      this.disableDatePicker = false;

      switch (period.value) {
        case "day":
          startDate = new Date(date.getTime());
          endDate = new Date(startDate);
          endDate.setDate(endDate.getDate() + 1);

          break;

        case "month":
          startDate.setDate(1);
          endDate = new Date(date.getTime());
          endDate.setMonth(endDate.getMonth() + 1);
          endDate.setDate(0);
          break;

        case "year":
          startDate = new Date(date.getTime());
          startDate.setDate(-1);
          startDate.setMonth(-1);
          endDate = new Date(date.getTime());
          endDate.setFullYear(endDate.getFullYear() + 1);
          endDate.setMonth(-1);

          break;

        case "all":
          startDate.setDate(-1);
          startDate.setMonth(-1);
          startDate.setFullYear(0);
          endDate = new Date();

          endDate.setFullYear(endDate.getFullYear() + 1);
          endDate.setMonth(-1);

          break;
      }

      // var yearLogsStartDate = new Date(date.getTime());
      //     var yearLogsEndDate = new Date(date.getTime());
      //     yearLogsStartDate.setDate(-1);
      //     yearLogsStartDate.setMonth(-1);
      //     yearLogsEndDate.setFullYear(yearLogsEndDate.getFullYear() + 1);
      //     yearLogsEndDate.setMonth(0);

      this.datalogs = await this.getLogs(
        this.station?.providerStationId,
        period?.value,
        startDate,
        endDate
      );

      // alert(JSON.stringify(this.datalogs));
      this.datalogs?.sort((a, b) => a.createdAt - b.createdAt);

      if (period?.value == "month" && this.datalogs.length) {
        this.datalogs = this.datalogs.map((el) => ({
          ...el,
          createdAt: new Date(el.createdAt).setHours(0),
        }));
      }

      if (period?.value == "all" && this.datalogs.length) {
        //Readapt start and endDate
        startDate = new Date(this.datalogs[0].createdAt);

        startDate.setDate(0);
        startDate.setMonth(0);

        endDate = new Date(this.datalogs[this.datalogs.length - 1].createdAt);
        endDate.setDate(1);
        endDate.setMonth(0);
        endDate.setFullYear(endDate.getFullYear() + 1);
      }

      dataCollection = {
        datasets: [
          {
            lineTension: 0,
            label: period?.value == "day" ? "Potência (kW)" : "Energia (kWh)",
            fill: true,
            data: [],
            borderColor:
              period?.value == "day"
                ? "rgba(101, 186, 255, 0.2)"
                : "rgba(38,51,82)",
            backgroundColor:
              period?.value == "day"
                ? "rgba(101, 186, 255, 0.2)"
                : "rgba(38,51,82)",
          },
        ],
      };

      for (var a = 0; a < this.datalogs?.length; a++) {
        dataCollection.datasets[0].data.push({
          x: new Date(this.datalogs[a].createdAt),
          y:
            period?.value == "day"
              ? this.datalogs[a].power
              : this.datalogs[a].energy,
        });
      }

      if (period?.value == "day")
        this.options = this.getLineChartOptions(startDate, endDate);
      else this.options = this.getBarChartOptions(startDate, endDate, period);

      this.dataCollection = dataCollection;

      this.loadingData = false;
    },

    async changeDate() {
      await this.loadChartData(this.periodSelected);
    },
    async changePeriod(period) {
      this.periodSelected = period;
      this.$forceUpdate();
      await this.loadChartData(period);
    },
    getLineChartOptions(startDate, endDate) {
      return {
        pointStyle: "none",
        elements: {
          point: {
            radius: 0,
          },
        },
        animation: {
          duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        bezierCurve: false,
        interaction: {
          intersect: false,
          mode: "index",
        },

        scales: {
          x: {
            type: "time", // Use the 'time' scale for the x-axis
            min: startDate.getTime() - 3 * 60 * 60 * 1000,
            max: endDate.getTime() - 3 * 60 * 60 * 1000,
            time: {
              unit: "minute", // Set the time unit to minutes
            },
            title: {
              display: true,
              text: "Hora",
            },
            grid: {
              display: false,
            },
          },
          y: {
            title: {
              display: true,
              text: "Potência (kW)",
            },
            grid: {
              display: false,
            },
          },
        },
      };
    },

    getBarChartOptions(startDate, endDate, period) {
      let unit;

      if (period?.value == "day") unit = "hour";
      if (period?.value == "month") unit = "day";
      if (period?.value == "year") unit = "month";
      if (period?.value == "all") unit = "year";

      let options = {
        pointStyle: "none",
        elements: {
          point: {
            radius: 0,
          },
        },
        animation: {
          duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        bezierCurve: false,
        interaction: {
          intersect: false,
          mode: "index",
        },

        scales: {
          x: {
            type: "time", // Use the 'time' scale for the x-axis
            min: startDate.getTime(),
            max: endDate.getTime(),
            time: {
              unit, // Set the time unit to minutes
            },
            grid: {
              display: false,
            },
          },
          y: {
            title: {
              display: true,
              text: "Energia (kWh)",
            },
            grid: {
              display: false,
            },
          },
        },
      };
      return options;
    },

    // essa func. formata a data do datepicker de acodo com o botao clicado
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      switch (this.periodSelected?.value) {
        case "day":
          this.filteredDate = day
            ? `${day}/${month}/${year}`
            : `01/${month}/${year}`;
          break;
        case "month":
          this.filteredDate = `${month}/${year}`;
          break;
        case "year":
          this.filteredDate = `${year}`;
        default:
          break;
      }
      return this.filteredDate;
    },
  },
};
</script>
<style scoped>
#iconTree {
  width: 50px;
  height: 50px;
}
</style>
