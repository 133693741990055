import functions from "./functions";
import store from "../../store";
var integrators = {
  //   async getAll(dateStart, dateEnd, encomendantes, exportadores) {
  //     console.log(JSON.stringify({ dateStart, dateEnd }));
  //     //integrators(filters:{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }} ]   }  ){

  //     var query = `query integrators{
  //         integrators( filters:{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }} ]   } ,pagination:{pageSize:1000}){
  //           data{
  //             attributes{
  //               DPE_NOM_FANTASIA_LIG
  //               PRI_DTA_ABERTURA
  //               transports{
  //                 data{
  //                   id
  //                    attributes{
  //                     ARMADOR

  //                     containers{
  //                       data{
  //                         id
  //                       }
  //                     }

  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }`;

  //     console.log(JSON.stringify({ query }));
  //     var graphql = JSON.stringify({
  //       query,
  //       variables: {},
  //     });
  //     //var url = "http://localhost:1337/graphql";
  //     var url = `${window.strapiUrl}/graphql`;
  //     console.log(JSON.stringify({ url }));
  //     var res = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${store?.state?.auth?.token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: graphql,
  //     })
  //       .then((res) => {
  //         console.log(res);
  //         return res.json();
  //       })
  //       .then((json) => {
  //         console.log(json);
  //         return json;
  //       });

  //     // if (res.data) {
  //     //   if (res.data.listarComponentes) {
  //     //     return res.data.listarComponentes;
  //     //   }
  //     // }
  //     return res;
  //   },

  async getAll() {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/integrators`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const integrators = functions.sanitize(res && res.data && res.data);
    //console.log(JSON.stringify({ stations }));

    return integrators;
  },

  async getWithPagination(page, pageSize, sort, sortDesc, filterName) {
    console.log(`sortDesc:${sortDesc}`);
    var textSort = sort ? `&sort[0]=${sort}${sortDesc ? ":desc" : ""}` : "";
    var textFilterName = filterName
      ? `&filters[name][$containsi]=${filterName}`
      : "";
    let url;
    if (pageSize && pageSize !== -1)
      url = `${process.env.VUE_APP_STRAPI_URL}/api/integrators?pagination[page]=${page}&pagination[pageSize]=${pageSize}${textFilterName}${textSort}`;
    else
      url = `${process.env.VUE_APP_STRAPI_URL}/api/integrators?pagination[page]=${page}&pagination[pageSize]=1000${textFilterName}${textSort}`;

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const values = functions.sanitize(res && res.data && res.data);
    //console.log(JSON.stringify({ stations }));
    const pagination = res.meta && res.meta.pagination;
    return { values, pagination };
  },

  async getById(integratorId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/integrators/${integratorId}`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async post(integrator, user) {
    //console.log(JSON.stringify({ integrator, user }));
    //integrators(filters:{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }} ]   }  ){

    integrator.ownerId = user.email;
    integrator.createdId = user.email;

    if (integrator.cnpj) integrator.cnpj = integrator.cnpj.replace(/\D/g, "");

    if (integrator.cpj) integrator.cpj = integrator.cpj.replace(/\D/g, "");

    //console.log(integrator.cnpj);
    //console.log(JSON.stringify({ length: integrator.cnpj.length }));
    var body = JSON.stringify({
      data: integrator,
    });
    //var url = "http://localhost:1337/graphql";
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/integrators`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async getUsers(integratorId, roleId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[integrator][id][$eq]=${integratorId}&filters[role][id]=${roleId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[integrator][id][$eq]=${integratorId}&populate=*`;

    // var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=${profileId}&filters[$and][1][integrator][id][$eq]=${integratorId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[integrator][id][$eq]=${integratorId}&populate[0]=*`;

    console.log(JSON.stringify({ url }));

    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getClients(integratorId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=4&filters[$and][1][integrator][id][$eq]=${integratorId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[integrator][id][$eq]=${integratorId}&populate[0]=*`;

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getStations(integratorId) {
    var url;

    if (integratorId == 1)
      url = `${process.env.VUE_APP_STRAPI_URL}/api/stations?pagination[pageSize]=1000`;
    else {
      url = `${process.env.VUE_APP_STRAPI_URL}/api/stations?filters[integrators][id][$eq]=${integratorId}&pagination[pageSize]=1000`;
    }
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    let stations = functions.sanitize(res && res.data && res.data);

    stations = stations.map((el) => ({
      ...el,
      address: `${el.streetAddress ? el.streetAddress + "," : ""}${
        el.neighborhood ? el.neighborhood + "," : ""
      }${el.cityFullName ? el.cityFullName + "," : ""}`,
    }));
    console.log(JSON.stringify({ stations }));

    return stations;
  },

  // async getStationNames(integratorId) {
  //   var filters = `{integrator:{id:{ eq:${integratorId}}} `;
  //   var query = `query stations{
  //     stations( pagination:{pageSize:10000}){
  //       data{
  //         attributes{
  //           id,name
  //         }
  //       }
  //     }
  //   }
  //   `;
  //   if (window.debug) console.log(JSON.stringify({ query }));
  //   var graphql = JSON.stringify({
  //     query,
  //     variables: {},
  //   });
  //   var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
  //   if (window.debug) console.log(JSON.stringify({ url }));
  //   var res = await fetch(url, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${store?.state?.auth?.token}`,
  //       "Content-Type": "application/json",
  //     },
  //     body: graphql,
  //   })
  //     .then((res) => {
  //       if (window.debug) console.log(res);
  //       return res.json();
  //     })
  //     .then((json) => {
  //       if (window.debug) console.log(json);
  //       return json;
  //     });
  //   const stations = functions.sanitize(res && res.data);
  //   console.log(JSON.stringify({ stations }));
  //   return stations;
  // },

  async getInverters(integratorId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/inverters?filters[integrators][id][$eq]=${integratorId}&populate=*`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    console.log(JSON.stringify({ data: res.data }));
    const values = functions.sanitize(res && res.data && res.data);
    const inverters = values.map((el) => ({
      ...el,
      manufacturerName: el?.manufacturer?.name,
    }));

    console.log(JSON.stringify({ inverters }));

    return inverters;
  },

  async put(value) {
    console.log("PUT" + JSON.stringify({ integrator }));
    const integrator = JSON.parse(JSON.stringify(value));
    if (integrator.cnpj) integrator.cnpj = integrator.cnpj.replace(/\D/g, "");

    if (integrator.cpj) integrator.cpj = integrator.cpj.replace(/\D/g, "");

    //var url = "http://localhost:1337/graphql";
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/integrators/${integrator.id}`;
    delete integrator["id"];

    var body = JSON.stringify({
      data: integrator,
    });

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
};

export default integrators;
