function fixRoleByEnvironment(role){
  switch(role){
    case "authenticated":
      if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'http://localhost:1337'){
        return 1
      }else if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'https://homolog.corsolar.com.br'){
        return 1
      }else {
        return 1
      }
    case "public":
      if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'http://localhost:1337'){
        return 2
      }else if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'https://homolog.corsolar.com.br'){
        return 2
      }else {
        return 2
      }
    case "admin":
        if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'http://localhost:1337'){
          return 3
        }else if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'https://homolog.corsolar.com.br'){
          return 4
        }else {
          return 6
        }
    case "engineer":
        if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'http://localhost:1337'){
          return 5
        }else if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'https://homolog.corsolar.com.br'){
          return 5
        }else {
          return 3
        }
    case "integrator":
        if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'http://localhost:1337'){
          return 6
        }else if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'https://homolog.corsolar.com.br'){
          return 3
        }else {
          return 4
        }
    case "client":
        if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'http://localhost:1337'){
          return 4
        }else if(process.env.VUE_APP_ENV == 'HOMOLOG' && process.env.VUE_APP_STRAPI_URL == 'https://homolog.corsolar.com.br'){
          return 6
        }else {
          return 5
        }
  }

}

const roleIds = {
  authenticated: fixRoleByEnvironment("authenticated"),
  public: fixRoleByEnvironment("public"),
  engineer: fixRoleByEnvironment("engineer"),
  integrator: fixRoleByEnvironment("integrator"),
  client: fixRoleByEnvironment("client"),
  admin: fixRoleByEnvironment("admin"),
};

export default roleIds;
