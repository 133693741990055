<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="2">
          <img v-if="hasImage" style="width: 60px" :src="imageUrl" />
        </v-col>

        <v-col class="py-4"
          ><span class="mt-2"> {{ provider?.name }} </span></v-col
        >
      </v-row>
    </v-card-title>
    <v-card-text class="mx-1">
      <span style="font-size: 12px">{{ provider?.url }}</span>
    </v-card-text>
    <v-card-text class="ma-4">
      <v-row v-if="provider?.integration">
        <p>Status :</p>
        <p v-if="provider?.integration?.token" style="color: green">Ativo</p>
        <p v-else style="color: red">Inativo</p>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="openModal">Editar</v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CardIntegration",
  methods: {
    openModal() {
      this.$emit("openModal", this.provider);
    },
  },

  computed: {
    strapiUrl() {
      return process.env.VUE_APP_STRAPI_URL;
    },

    imageUrl() {
      return `${process.env.VUE_APP_STRAPI_URL}${this.provider?.logo?.formats?.thumbnail?.url}`;
    },
    hasImage() {
      return this.provider?.logo?.formats?.thumbnail?.url ? true : false;
    },
  },
  props: ["provider"],
  data() {
    return {};
  },
};
</script>

<style></style>
