var updatePowerPlant = {
    //aqui é a função que é chamada no importXlsx.vue para atualizar o site a partir dos serial numbers
    async insertingUserData(dados, id) {
        const db = window.firebase.firestore();
        // é importante usar a func 'update', pois ela atualiza o campo caso já exista e cria um novo caso nao.
        var ref = db.collection("databases/corsolar/stations")

        const ans = await ref
        .doc(id)
        .update({
            "stationName": dados.plantName,
            "sn": dados.sn,
            // "owner": dados.owner,

        })
        .catch((error) => {
            if (window.debug) console.log("Error getting documents: ", error);
            return error;
        });

        return ans
        
      },
}
export default updatePowerPlant;