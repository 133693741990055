<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      shaped
      absolute
      color="green"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-data-table
      :headers="headers"
      :items="users"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Gestão de usuários</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="actionButton" dark class="mb-2" @click="goToPage()">
            Importar usinas
          </v-btn>
        </v-toolbar>

        <!-- Dialogues -->
        <!-- Todos os dialogs estão aqui -->
        <!-- Visualiza usina - é acionado ao clicar no icone de olho. Ele mostra todas as usinas associadas -->
        <v-dialog v-model="dialogViewStations" max-width="1500px" persistent>
          <v-card>
            <v-card-title
              >Todas as usinas do(a)
              <span class="font-weight-bold">{{ exibe.name }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    xs="6"
                    sm="6"
                    md="4"
                    xl="4"
                    class="text-center"
                    v-for="station in exibeStations"
                    :key="station.user"
                  >
                    <v-btn
                      small
                      color="actionButton"
                      @click="goToStation(station)"
                    >
                      {{ station.stationName }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-divider inset></v-divider>
                <v-row justify="end" class="mt-3">
                  <v-col xs="6" sm="2" md="2" xl="1">
                    <v-btn
                      color="red"
                      small
                      class="elevation-0"
                      @click="closeDialog"
                    >
                      Fechar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Edita usuario -->
        <!-- esse dialog é acionado pelo icone de lápis. Aqui é onde se edita as informações do usuario -->
        <v-dialog v-model="dialogEditUser" max-width="1500px" persistent>
          <v-card>
            <v-card-title
              >Editar <span class="font-weight-bold">{{ exibe.name }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" xs="12" sm="6" md="3" xl="3">
                    <v-text-field
                      v-model="updatedUser.name"
                      label="Nome: "
                      :placeholder="exibe.name"
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="6" md="3" xl="3">
                    <v-text-field
                      v-model="updatedUser.email"
                      label="E-mail: "
                      :placeholder="exibe.email"
                      persistent-placeholder
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" xs="12" sm="6" md="3" xl="3">
                    <v-select
                      :items="lista_de_accessLevel"
                      label="Nível de acesso: "
                      :placeholder="exibe.accessLevel"
                      persistent-placeholder
                      clearable
                      v-model="updatedUser.accessLevel"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" xs="12" sm="6" md="3" xl="3">
                    <v-select
                      :items="lista_de_status"
                      label="Status: "
                      :placeholder="exibe.status"
                      persistent-placeholder
                      clearable
                      v-model="updatedUser.status"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-divider inset></v-divider>
                <v-row justify="end" class="mt-3">
                  <v-col xs="6" sm="2" md="2" xl="1" class="text-right">
                    <v-btn color="red" depressed small @click="closeDialog">
                      Cancelar
                    </v-btn>
                  </v-col>
                  <v-col xs="6" sm="2" md="1" xl="1" class="text-right">
                    <v-btn
                      color="actionButton"
                      depressed
                      small
                      @click="atualizaUser(exibe)"
                    >
                      Atualizar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Edita usina -->
        <!-- Acionado pelo icone de usina. Responsavel por apresentar as tabelas de usinas disponíveis e associadas -->
        <v-dialog
          v-model="dialogEditStations"
          max-width="1500px"
          persistent
          style="overflow: hidden; min-height: 600px"
        >
          <v-toolbar dark class="actionButton">
            <v-row>
              <span style="font-size: 16px"
                >Associe uma usina ao usuário {{ exibe.name }}</span
              >
              <!-- <span class="font-weight-bold">{{ exibe.name }}</span> -->
              <v-spacer></v-spacer>
              <v-btn class="actionButton" @click="closeDialog">X</v-btn>
            </v-row>
          </v-toolbar>
          <v-card>
            <v-card-text>
              <v-container
                style="overflow: scroll; max-height: 450px; min-width: 100%"
              >
                <v-row>
                  <v-col cols="12" xs="12" sm="4" md="4" xl="4">
                    <v-subheader>Usinas selecionadas</v-subheader>
                    <v-data-table
                      :headers="headersStationsEdited"
                      :items="selected"
                      hide-default-footer
                      hide-default-header
                    >
                      <template v-slot:[`item.delete`]="{ item }">
                        <v-icon @click="removeStations(item)" class="red--text">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" xs="12" sm="8" md="8" xl="8">
                    <v-subheader>Usinas disponíveis</v-subheader>
                    <!-- <v-row> -->
                    <v-text-field
                      single-line
                      label="Pesquisar"
                      append-icon="mdi-magnify"
                      v-model="search"
                      hide-details
                      filled
                    ></v-text-field>
                    <v-data-table
                      :headers="headersStations"
                      :items="stations"
                      :expanded.sync="expanded"
                      show-expand
                      show-select
                      checkbox-color="actionButton"
                      :selectable-key="stations.providerStationId"
                      v-model="selected"
                      :search="search"
                    >
                      <!-- Esse é o menu expansível, onde há info. extras a respeito da usina -->
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-subheader
                            >Mais informações sobre a estação</v-subheader
                          >
                          <div class="mb-1">
                            <v-icon small class="mr-3 primary--text"
                              >mdi-key</v-icon
                            >
                            {{ item.providerStationId }}
                          </div>
                          <div class="mb-1">
                            <v-icon small class="mr-3 primary--text"
                              >mdi-solar-panel</v-icon
                            >
                            {{ item.snStr ? item.snStr : item.sn }}
                          </div>
                          <div class="mb-1">
                            <v-icon small class="mr-3 red--text"
                              >mdi-map-marker</v-icon
                            >
                            {{ item.regionStr }}, {{ item.countyStr }} -
                            {{ item.countryStr }}
                          </div>
                        </td>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-divider inset></v-divider>
              </v-container>
              <v-row justify="end" class="mt-3">
                <v-col xs="6" sm="2" md="2" xl="1" class="text-right">
                  <v-btn color="red" depressed small @click="closeDialog">
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col xs="6" sm="2" md="1" xl="1" class="text-right">
                  <v-btn
                    color="actionButton"
                    depressed
                    small
                    @click="atualizaUsina(exibe)"
                  >
                    Atualizar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Exclui usuário -->
        <!-- Reconhecido pelo icone de lixeira, tem a func. de excluir um usuario do banco -->
        <v-dialog v-model="dialogDeleteUser" max-width="1500px" persistent>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row justify="center">
                  <v-col cols="6" class="text-center">
                    Deseja excluir o usuário
                    <span class="font-weight-bold">{{ exibe.name }}</span
                    >?
                  </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-divider inset></v-divider>
                <v-row justify="end" class="mt-3">
                  <v-col xs="6" sm="2" md="2" xl="1" class="text-right">
                    <v-btn
                      color="actionButton"
                      depressed
                      small
                      @click="closeDialog"
                    >
                      Cancelar
                    </v-btn>
                  </v-col>
                  <v-col xs="6" sm="2" md="1" xl="1" class="text-right">
                    <v-btn
                      color="red"
                      depressed
                      small
                      @click="deleteUser(exibe)"
                    >
                      Excluir
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <!-- esse slot é responsável por mostrar o stations associados, ele ativa o dialog que exibe isso -->
      <template v-slot:[`item.Stations`]="{ item }">
        <v-icon color="actionButton" small @click="viewStations(item)"
          >mdi-eye</v-icon
        >
      </template>
      <!-- Nesse slot aqui fica os chips, que mostram os status do usuário -->
      <template v-slot:[`item.status`]="{ item }">
        <v-row>
          <v-col class="">
            <v-chip :color="getColor(item.status)" dark>
              <span class="body-2">{{ item.status }}</span>
            </v-chip>
          </v-col>
        </v-row>
      </template>
      <!-- Nesse slot ficam os ícones de ações -->
      <template v-slot:[`item.config`]="{ item }">
        <v-icon class="mx-2 primary--text" @click="editUser(item)">
          mdi-pencil
        </v-icon>
        <v-icon class="primary--text mx-2" @click="editStations(item)">
          mdi-factory
        </v-icon>
        <!-- <v-icon
          class="red--text text--darken-1 mx-2"
          @click="dialogueDelete(item)"
        >
          mdi-delete
        </v-icon> -->
      </template>
    </v-data-table>
    <!-- paginação -->
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        color="actionButton"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import { mapState } from "vuex";

export default {
  name: "dataTable",
  props: {
    StationsSelected: {},
    userStations: {},
    stations: {},
    selected: [],
    exibe: {},
    exibeStations: {},
    headers: {},
    headersStations: {},
    headersStationsEdited: {},
    dialogViewStations: {},
    dialogEditUser: {},
    dialogEditStations: {},
    lista_de_status: {},
    editUser: { type: Function },
    viewStations: { type: Function },
    editStations: { type: Function },
    getColor: { type: Function },
    closeDialog: { type: Function },
    goToPage: { type: Function },
    goToStation: { type: Function },
    lista_de_accessLevel: {},
    dialogDeleteUser: {},
    dialogueDelete: { type: Function },
    users: [],
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "Atualizado!",
      timeout: 5000,
      expanded: [],
      page: 1,
      //users: [],
      pageCount: 0,
      itemsPerPage: 10,
      updatedUser: {
        name: "",
        email: "",
        status: "",
        accessLevel: "",
      },
      search: "",
      stationUsers: {},
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  async mounted() {},
  methods: {
    // remove stations dentro da edição de usinas
    removeStations(item) {
      console.log(this.selected);
      this.selected.splice(this.selected.indexOf(item), 1);
    },
    // ao clicar em atualizar uma usina dentro do dialog editaUsina, ele atualiza os as stations do usuário, na tabela users no documento onde contém as informações do usuário
    async atualizaUsina(user) {
      await corsolarApi.users.updateStations(user, this.selected);

      this.selected = [];
      this.snackbar = true;
      this.snackbarText =
        "Atualizado! A(s) Usina(s) já está(ão) disponível(eis)";
      this.closeDialog();
    },
    // atualiza as informações do usuario no banco, la no documento 'users'
    async atualizaUser(dados) {
      if (this.updatedUser.name == "") {
        this.updatedUser.name = this.exibe.name;
      }
      if (this.updatedUser.email == "") {
        this.updatedUser.email = this.exibe.email;
      }
      if (this.updatedUser.status == "") {
        this.updatedUser.status = this.exibe.status;
      }
      if (this.updatedUser.accessLevel == "") {
        this.updatedUser.accessLevel = this.exibe.accessLevel;
      }
      this.updatedUser.uid = dados.uid;
      await corsolarApi.users.update(this.updatedUser);
      await this.updateTable();
      this.updatedUser = {
        name: "",
        email: "",
        status: "",
        accessLevel: "",
      };
      this.snackbar = true;
      this.snackbarText = "Usuário atualizado!";
      await this.closeDialog();
    },
    async updateTable() {
      // sem esse timeout de 100 o firebase buga
      setTimeout(async () => {
        this.users = await corsolarApi.users.getAll();
      }, 100);
    },
    // chama a função que deleta um user do banco
    async deleteUser(user) {
      await corsolarApi.users.delete(user);
      await this.updateTable();
      this.closeDialog();
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>
