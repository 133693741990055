//import { directive as vClickOutside } from "vue-clickaway";

import moment from "moment";
import Vue from "vue";
/**
import Vue from 'vue'
 * You can register global components here and use them as a plugin in your main Vue instance
 */

Vue.directive("money-format", (el, { value }) => {
  //console.log(value);
  el.innerHTML = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
    minimumFractionDigits: 2,
  }).format(value || 0);
});

Vue.directive("decimal-format", (el, { value }) => {
  //console.log(value);
  var d = Math.round(value * 100) / 100;
  el.innerHTML = new Intl.NumberFormat("pt-BR", {
    //  currency: "BRL",
    // style: "currency",
    minimumFractionDigits: 2,
  }).format(d || 0);
});

Vue.directive("decimal-format-3", (el, { value }) => {
  //console.log(value);
  var d = Math.round(value * 1000) / 1000;
  el.innerHTML = new Intl.NumberFormat("pt-BR", {
    //  currency: "BRL",
    // style: "currency",
    minimumFractionDigits: 2,
  }).format(d || 0);
});

Vue.directive("date-format", (el, { value }) => {
  el.innerHTML = moment(value).format("DD/MM/YYYY [às] HH:mm:ss ");
});

Vue.directive("cnpj-format", (el, { value }) => {
  var v = value;
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
  v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
  el.innerHTML = v;
});

Vue.directive("cpf-format", (el, { value }) => {
  var v = value;
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos                                         //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
  el.innerHTML = v;
});

Vue.directive("date-format-iso", (el, { value }) => {
  var d = new Date(value);
  el.innerHTML = d.toLocaleDateString("pt-BR");
});

// var d = new Date(this.object.Date);
// return d.toLocaleDateString('pt-BR')
Vue.decimalFormat = function (value) {
  var d = Math.round(value * 100) / 100;
  return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
    d || 0
  );
};

const GlobalDirectives = {
  // install(Vue) {
  //   Vue.directive("click-outside", vClickOutside);
  // },
};

export default GlobalDirectives;
