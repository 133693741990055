<template>
  <v-container>
    <!-- <v-dialog v-model="openDialog"> <p>Test</p> </v-dialog> -->
    <ModalIntegration
      v-model="openDialog"
      :integration-id="integrationEditId"
      :integrator="integrator"
      profile-id="4"
      :integratorId="integratorId"
      :provider="editingProvider"
      @update="updateIntegration"
    ></ModalIntegration>
    <v-container v-for="(provider, index) in providers" :key="index">
      <CardIntegration
        :provider="provider"
        @openModal="openModalintegration"
      ></CardIntegration>
    </v-container>
  </v-container>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import ModalIntegration from "../../components/modals/ModalIntegration.vue";
import CardIntegration from "./CardIntegration.vue";
export default {
  name: "CardIntegrations",
  components: {
    ModalIntegration,
    CardIntegration,
  },
  props: ["integratorId", "integrator", "profileId"],
  data() {
    return {
      openDialog: false,
      integrations: [],
      providers: [],
      integrationEditId: null,
      editingProvider: {},
      availableIntegrations: [
        {
          manufacturer: {
            id: 1,
            name: "",
          },
        },
      ],
    };
  },
  async mounted() {
    this.providers = await strapiApi.providers.getAll();
    this.integrations = await this.getIntegrations();
  },

  methods: {
    // async update() {
    //   this.integrations = await this.getIntegrations();

    //   this.openDialog = false;
    // },

    updateIntegration(data) {
      //alert("updateIntegration");

      const { providerId, integration } = data;

      //alert(JSON.stringify(integration));
      this.providers = this.providers.map((el) => ({
        ...el,
        integration: el.id == providerId ? integration : el.integration,
      }));
    },

    async getIntegrations() {
      //alert(this.integratorId);
      const integrations = await strapiApi.integrations.getAll(
        parseInt(this.integratorId)
      );

      this.providers = this.providers.map((el) => {
        let integration = integrations.find((i) => i.provider?.id == el.id);
        if (!integration) integration = {};
        integration.password = "";
        return {
          integration,
          ...el,
        };
      });

      return integrations;
    },

    openModalintegration(provider) {
      this.editingProvider = provider;
      this.openDialog = true;
    },
  },
};
</script>

<style>
.custom-table .v-data-table__wrapper {
  border: none;
}

.custom-table .v-data-table__thead > tr > th:not(:last-child),
.custom-table .v-data-table__tbody > tr > td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}
table td,
th {
  border-left: 1px solid #dddddd;
}
</style>
