<template>
  <LineChartGenerator
    v-if="chartOptions && chartData"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import "chartjs-adapter-moment";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
  TimeScale,
} from "chart.js";

// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   LineElement,
//   LinearScale,
//   CategoryScale,
//   PointElement,
//   Filler,
// } from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  PointElement,
  Filler
);

// ChartJS.register(
//   Title,
//   Tooltip,
//   Legend,
//   LineElement,
//   LinearScale,
//   CategoryScale,
//   PointElement,
//   Filler
// )

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: [
    "chartId",
    "datasetIdKey",
    "width",
    "height",
    "cssClasses",
    "styles",
    "plugins",
    "chartData",
    "chartOptions",
  ],

  data() {
    return {};
  },
};
</script>
