import functions from "./functions";
import store from "../../store";
import { saveAs } from 'file-saver';
var stations = {
  async getAll() {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stations`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const stations = functions.sanitize(res && res.data && res.data);
    //console.log(JSON.stringify({ stations }));

    return stations;
  },

  async resumeCsv(date){
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stations/resume`;

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
        // "Content-Type": "application/octet-stream",
        // "Accept": "application/octet-stream"
      },
      body:JSON.stringify({
        date : date ? date : ""
      })
    })
    .then(response => response.blob())
    .then(blob => saveAs(blob,'resume.xlsx'))
    .catch((error) => {
      if (window.debug) console.log("Error - Backend: ", error);
      return error;
    });

    return res
  },

  async getSummary(userId, onlyVisible) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/station/summary?${
      onlyVisible ? "onlyVisible=true" : ""
    }`;

    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async getWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    filterName,
    integratorId,
    onlyVisible,
    searchIntegrator,
  ) {
    console.log(`sortDesc:${sortDesc}`);
    var textSort = sort ? `&sort[0]=${sort}${sortDesc ? ":desc" : ""}` : "";
    // var textFilterName = filterName
    //   ? `&filters[name][$containsi]=${filterName}`
    //   : "";
    console.log(JSON.stringify({ filterName }));
    console.log(JSON.stringify({ integratorId }));
    let textFilterName;
    if (integratorId && !(integratorId == 1)) {
      textFilterName = filterName
        ? `&filters[$and][0][name][$containsi]=${filterName}&filters[$and][1][integrators][id]=${integratorId}${
            onlyVisible
              ? "&filters[$and][2][$or][0][hidden]=false&filters[$and][2][$or][1][hidden][$null]=true"
              : ""
          }`
        : `&filters[$and][0][integrators][id]=${integratorId}${
            onlyVisible
              ? "&filters[$and][1][$or][0][hidden]=false&filters[$and][1][$or][1][hidden][$null]=true"
              : ""
          }`;
    }else if(searchIntegrator){
      textFilterName = filterName
      ? `&filters[integrators][name][$containsi]=${filterName}${
        onlyVisible
        ? "&filters[$and][0][$or][0][hidden]=false&filters[$and][0][$or][1][hidden][$null]=true"
        : ""
      }` : `${
        onlyVisible
          ? "&filters[$and][1][$or][0][hidden]=false&filters[$and][1][$or][1][hidden][$null]=true"
          : ""
      }`;
    }else {
      textFilterName = filterName
        ? `&filters[$and][0][name][$containsi]=${filterName}${
            onlyVisible
              ? "&filters[$and][0][$or][0][hidden]=false&filters[$and][0][$or][1][hidden][$null]=true"
              : ""
          }`
        : `${
            onlyVisible
              ? "&filters[$and][1][$or][0][hidden]=false&filters[$and][1][$or][1][hidden][$null]=true"
              : ""
          }`;
    }
    console.log(JSON.stringify({ textFilterName }));

    let url;
    if (pageSize && pageSize !== -1)
      url = `${process.env.VUE_APP_STRAPI_URL}/api/stations?populate=manufacturer,integrators&pagination[page]=${page}&pagination[pageSize]=${pageSize}${textFilterName}${textSort}`;
    else
      url = `${process.env.VUE_APP_STRAPI_URL}/api/stations?populate=manufacturer,integrators&pagination[page]=${page}&pagination[pageSize]=1000${textFilterName}${textSort}`;


    console.log(JSON.stringify({ url }));
    console.log(`token:${store?.state?.auth?.token}`);
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });


    let values = functions.sanitize(res && res.data && res.data);

    values = values.map((el) => ({
      ...el,
      address: `${el.streetAddress ? el.streetAddress + "," : ""}${
        el.neighborhood ? el.neighborhood + "," : ""
      }${el.cityFullName ? el.cityFullName + "," : ""}`,
    }));
    //console.log(JSON.stringify({ stations }));
    const pagination = res.meta && res.meta.pagination;
    return { values, pagination };
  },

  async getByUserId(userId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stations?populate[0]=manufacturer`;
    if (userId) {
      url += `&filters[users][id]=${userId}`;
    } else {
      return [];
    }
    console.log(url)
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    console.log(JSON.stringify({ res }));
    let stations = functions.sanitize(res.data);
    console.log(JSON.stringify({ stations }));
    return stations;
  },

  async getById(stationId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stations/${stationId}?populate[0]=manufacturer`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    console.log(JSON.stringify({ res }));
    let station = functions.sanitize(res.data);
    console.log(JSON.stringify({ station }));
    return station;
  },

  async post(station, user) {
    //console.log(JSON.stringify({ station, user }));
    //stations(filters:{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }} ]   }  ){

    station.ownerId = user.email;
    station.createdId = user.email;

    if (station.cnpj) station.cnpj = station.cnpj.replace(/\D/g, "");

    if (station.cpj) station.cpj = station.cpj.replace(/\D/g, "");

    //console.log(station.cnpj);
    //console.log(JSON.stringify({ length: station.cnpj.length }));
    var body = JSON.stringify({
      data: station,
    });
    //var url = "http://localhost:1337/graphql";
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stations`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async getUsers(stationId, profileId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=${profileId}&filters[$and][1][station][id][$eq]=${stationId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[station][id][$eq]=${stationId}&populate[0]=*`;

    console.log(JSON.stringify({ url }));

    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getClients(stationId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=4&filters[$and][1][station][id][$eq]=${stationId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[station][id][$eq]=${stationId}&populate[0]=*`;

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getStations(stationId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stations?filters[stations][id][$eq]=${stationId}`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const stations = functions.sanitize(res && res.data && res.data);
    console.log(JSON.stringify({ stations }));

    return stations;
  },

  async getInverters(stationId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/inverters?filters[stations][id][$eq]=${stationId}`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const inverters = functions.sanitize(res && res.data && res.data);
    console.log(JSON.stringify({ inverters }));

    return inverters;
  },

  async put(value) {
    const station = JSON.parse(JSON.stringify(value));
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stations/${station.id}`;
    delete station["id"];
    var body = JSON.stringify({
      data: station,
    });

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async toggleHidden(station) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stations/${station.id}`;
    delete station["id"];
    var body = JSON.stringify({
      data: { hidden: !station.hidden },
    });

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
};

export default stations;
