<template>
  <v-dialog v-model="showModal" width="600px">
    <v-card class="pa-0">
      <v-toolbar dark class="actionButton">
        <span v-if="editingUser?.id">Editar usuário </span>
        <span v-else>Novo usuário </span>
        <v-spacer></v-spacer>
        <v-btn color="white darken-1" text @click="close"> X </v-btn>
      </v-toolbar>
      <v-card-actions> </v-card-actions>

      <div class="px-6">
        <v-card-text>
          <v-row>
            <v-text-field
              v-model="editingUser.username"
              label="Nome"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-model="editingUser.email"
              label="E-mail"
            ></v-text-field>
          </v-row>
          <v-row v-if="!editingUser?.id">
            <v-text-field
              v-model="editingUser.password"
              label="Password"
              type="password"
            ></v-text-field>

            <!-- <v-col cols="6">
            <v-text-field v-model="user.email" label="E-mail"></v-text-field>
          </v-col> -->
          </v-row>
          <v-row>
            <v-spacer></v-spacer>

            <v-btn class="primary" @click="save">Salvar</v-btn>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { mapState, mapMutations } from "vuex";
export default {
  name: "modal-user",
  props: ["value", "userId", "integratorId"],
  data() {
    return {
      editingUser: {},
    };
  },
  computed: {
    showModal: {
      get() {

        return this.value;
      },
      set(value) {
        // alert("emit");
        this.$emit("input", value);
        // this.$emit("update", value);
      },
    },
  },
  watch: {
    userId(val, oldVal) {
      if (val === oldVal) return;
      //alert(`changed user:${val}`);
      this.loadUser(val);
    },
    value(val, oldVal) {
      if (val === oldVal) return;
      //alert(`changed user:${val}`);
      this.loadUser(this.userId);
    },
  },

  methods: {
    ...mapMutations({
      setAlert: "SET_ALERT",
    }),
    close() {
      //alert("close");
      this.showModal = false;
      // this.$emit("input", false);
    },
    async save() {
      console.log(strapiApi.roleIds);
      console.log(this.userId);
      // if (this.userId == "new") {
      //   this.editingUser.integrator = parseInt(this.integratorId);
      //   console.log(this.editingUser)

      //   console.log(strapiApi.roleIds.integrator)
      //   var ans = await strapiApi.users.post(
      //     this.editingUser,
      //     strapiApi.roleIds.integrator
      //   );
      //   if (ans.error) {
      //     //alert(JSON.stringify(ans.error));
      //     this.setAlert({
      //       alertMsg: `${JSON.stringify(ans.error)}`,
      //       alertType: "error",
      //     });
      //   } else {
      //     // alert(`Usuário ${ans.email} adicionado `);
      //     this.setAlert({
      //       alertMsg: `Usuário ${ans.email} adicionado `,
      //       alertType: "success",
      //     });
      //     this.$emit("update");
      //   }
      // } else {
      //   let ans = await strapiApi.users.update(this.editingUser);
      //   if (ans.error) {
      //     //alert(JSON.stringify(ans.error));
      //     this.setAlert({
      //       alertMsg: `${JSON.stringify(ans.error)}`,
      //       alertType: "error",
      //     });
      //   } else {
      //     //alert(`Usuário ${ans.email} atualizado `);
      //     this.setAlert({
      //       alertMsg: `Usuário ${ans.username} atualizado `,
      //       alertType: "success",
      //     });
      //     this.$emit("update");
      //   }
      // }
      //alert(JSON.stringify({ ans }));
    },

    async loadUser(userId) {
      if (userId == "new") {
        this.editingUser = {
          email: "",
          //   name: "",
          role: 1,
        };
      } else {
        this.editingUser = await strapiApi.users.getById(userId);
      }
    },
  },
};
</script>

<style scoped></style>
