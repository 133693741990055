<template>
  <v-container fluid class="pa-4">
    <v-row class="pa-4">
      <p>Dados</p>
    </v-row>
    <v-container v-if="integrator" class="pa-4">
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="integrator.name" label="Nome"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="integrator.email"
            label="E-mail"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7">
          <v-text-field
            v-model="integrator.legalName"
            label="Razão social"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="integrator.phone"
            label="Telefone"
            v-mask="['(##) ##### ####']"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="7">
          <v-text-field
            v-model="integrator.cnpj"
            label="CNPJ"
            v-mask="['##.###.###/####-##']"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="integrator.zipCode"
            @blur="searchZipCode"
            label="CEP"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="integrator.stateId"
            label="Estado"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="integrator.cityId"
            label="Cidade"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="integrator.neighborhood"
            label="Bairro"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="integrator.streetAddress"
            label="Endereço"
          ></v-text-field>
        </v-col>
        <!-- <v-col>
          <v-file-input
            v-model="integrator.profilePhoto"
            :rules="rules"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Selecione uma foto"
            prepend-icon="mdi-camera"
            label="Foto de perfil"
          ></v-file-input>
        </v-col> -->
      </v-row>

      <v-row>
        <v-spacer></v-spacer>

        <v-btn class="actionButton" @click="save">Salvar</v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import GlobalFunctions from "../../../../libraries/functions/globalFunctions";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { mapState, mapMutations } from "vuex";
export default {
  name: "CardIntegratorInfo",
  props: ["integrator", "integratorId"],

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapMutations({
      setAlert: "SET_ALERT",
    }),
    async searchZipCode(value) {
      var zipCode = this.integrator.zipCode;
      zipCode = zipCode.replace(/\D/g, "");
      if (this.integrator.zipCode.length == 8) {
        let ans = await GlobalFunctions.searchZipCode(zipCode);
        this.integrator.cityId = ans.cityName;
        this.integrator.stateId = ans.stateName;
        this.integrator.neighborhood = ans.neighborhood;
        this.integrator.streetAddress = ans.streetAddress;
      }
    },
    async save() {
      console.log(`save: ${this.integrator.id}`);
      if (this.integratorId == "new") {
        const ans = await strapiApi.integrators.post(
          this.integrator,
          this.user
        );

        console.log(JSON.stringify({ ans }));
        if (ans && ans.data && ans.data.id) {
          this.setAlert({
            alertMsg: `Integrador cadastrado com sucesso`,
            alertType: "success",
          });
          this.$emit("saved", ans.data.id);
        }

        if (ans && ans.error) {
          this.setAlert({
            alertMsg: `${JSON.stringify(ans.error)}`,
            alertType: "error",
          });
        }
      } else {
        console.log("Will UPDATE");
        const ans = await strapiApi.integrators.put(this.integrator);
      }
    },
  },
};
</script>

<style></style>
