<template>
  <v-container style="width: 300px" pa-0 mx-2>
    <!-- <v-card color=""> -->
    <!-- <p>Lateral panel</p> -->

    <div v-for="(item, i) in items" fluid>
      <v-btn
        @click="select(i)"
        style="margin: 5px; width: 100%"
        :class="{ actionButton: value == i }"
      >
        {{ item.text }}
      </v-btn>
    </div>

    <!-- </v-card> -->
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  model: {
    prop: "selectedIndex",
    event: "change",
  },
  name: "LateralPanel",
  props: ["selectedIndex", "disabled"],
  emits: ["update:selectedIndex"],
  computed: {
    value: {
      get() {
        return this.selectedIndex;
      },
      set(value) {
        this.$emit("update:selectedIndex", value);
      },
    },
  },

  data() {
    return {
      //selectedIndex: 0,
      items: [
        { text: "Dados" },
        { text: "Usuários" },
        { text: "Usinas" },
        { text: "Inversores" },
        { text: "Integrações" },
        { text: "Clientes" },

        //   { text: "Clientes" },
      ],
    };
  },

  methods: {
    ...mapMutations({
      setAlert: "SET_ALERT",
    }),
    select(index) {
      if (this.disabled) {
        this.setAlert({
          alertMsg: `Favor salvar a empresa antes de prosseguir`,
          alertType: "info",
        });
      } else {
        this.value = index;
        this.$emit("change", index);
      }
    },
  },
};
</script>

<style></style>
