
var debug = false;
var GlobalFunctions = {
  //db = this.$firebase.firestore();

  name:"teste",
  optionsMoneyBr: {
    locale: "pt-BR",
    prefix: "R$",
    suffix: "",
    length: 11,
    precision: 2,
  },
  formatDecimal(value){
    var d =  Math.round(value*100)/100
     var r  = new Intl.NumberFormat('pt-BR', {
    //  currency: "BRL",
     // style: "currency",
      minimumFractionDigits: 2
    }).format(d || 0)

   return r;
  },

  async searchZipCode(value){
    if(!value)
      return false

    var cep = value.replace(/\D/g, '');
    if (cep != "") {
        //Make fetch to get al lthe informations
        let url = "https://viacep.com.br/ws/"+ cep +"/json";
        var info = await  fetch(url,{
        method: 'get',
        mode:"cors",    
        headers: { 'Content-Type': 'application/json' },
        })
            .then(res=>{
                console.log(res)
                return res.json();
            })
            .then(json=>{
                console.log(json)
                var infoCep = {
                                cityFullName: json.localidade + " - " + json.uf,
                                cityName:json.localidade,
                                stateName:json.uf,
                                neighborhood : json.bairro,
                                streetAddress: json.logradouro
                };
              
                if(json.localidade == null)
                    infoCep = {
                                    cidade: "",
                                    bairro : "",
                                    rua: ""
                    };
                
                    return infoCep
            }) 
            .catch((error) => {
              console.log(error)
            });
        };

        //alert(JSON.stringify(info));

        return info;
  },

  async consultaCpfReceita(cpf){
    if(!cpf)
      return false;

    var rawCpf = cpf.replace(/[./-]+/g, "");
    var valid =  ( rawCpf.length==11);
    return valid;

  },

  async consultaCnpjReceita(cnpj){
    if(!cnpj)
      return false;

    var rawCnpj = cnpj.replace(/[./-]+/g, "");
    var valid  =( rawCnpj.length==14);
    return valid;

  },
  
  async consultaCnpjSintegra(cnpj){
    if(!cnpj)
      return false;


    var rawCnpj = cnpj.replace(/[./-]+/g, "");
    var valid  =( rawCnpj.length==14);
    return valid;
    
  },
  
    
}


export default GlobalFunctions;