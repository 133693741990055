<template>
  <v-dialog v-model="openModal" width="600px">
    <v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="close"> Fechar </v-btn>
      </v-card-actions>
      <v-card-title> Novo inversor </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="editingInverter.sn"
              label="SN"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              label="Select"
              v-model="manufacturerId"
              :items="items"
              item-text="text"
              item-value="value"
            ></v-select>
          </v-col>
          <!-- <v-col cols="6">
            <v-text-field
              v-model="editingInverter.password"
              label="Password"
              type="password"
            ></v-text-field>
          </v-col> -->
          <!-- <v-col cols="6">
            <v-text-field v-model="user.email" label="E-mail"></v-text-field>
          </v-col> -->
        </v-row>
        <v-row>
          <v-spacer></v-spacer>

          <v-btn class="primary" :disabled="loading" @click="save"
            >Salvar
            <v-progress-circular
              v-show="loading"
              indeterminate
              color="white"
            ></v-progress-circular
          ></v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { mapState, mapMutations } from "vuex";
export default {
  name: "modal-inverter",
  props: ["value", "inverterId", "integratorId", "profileId"],
  data() {
    return {
      editingInverter: {},
      manufacturerId: "solis",
      loading: false,
      items: [{ text: "Solis", value: "solis" }],
    };
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(value) {
        // alert("emit");
        this.$emit("input", value);
        // this.$emit("update", value);
      },
    },
  },
  watch: {
    inverterId(val, oldVal) {
      if (val === oldVal) return;
      //alert(`changed user:${val}`);
      this.loadInverter(val);
    },
  },

  methods: {
    ...mapMutations({
      setAlert: "SET_ALERT",
    }),
    close() {
      //alert("close");
      this.$emit("input", false);
    },
    async save() {
      this.loading = true;
      if (this.inverterId == "new") {
        this.editingInverter.integratorId = parseInt(this.integratorId);
      }

      var ans = await strapiApi.inverters.new(
        this.editingInverter,
        this.manufacturerId
      );

      if (ans.error) {
        this.setAlert({
          alertMsg: `${ans.error} `,
          alertType: "error",
        });
      } else {
        console.log(JSON.stringify(ans));
        if (ans && ans.msg) {
          this.setAlert({
            alertMsg: `${ans.msg} `,
            alertType: "success",
          });
        }
        this.$emit("update");
      }
      this.loading = false;
    },

    async loadInverter(inverterId) {
      if (inverterId == "new") {
        this.editingInverter = {
          sn: "",
        };
      } else {
        this.editingInverter = await strapiApi.inverter.getById(userId);
      }
    },
  },
};
</script>

<style scoped></style>
