<template>
  <v-container>
    <template v-if="integratorId != 'new'">
      <!-- <IntegratorManage :integrator="integrator" /> -->
    </template>
    <!-- <v-card v-if="integratorId == 'new'">
      <v-card-title> Novo integrador </v-card-title>
    </v-card> -->
    <v-container style="display: flex" fluid>
      <LateralPanel
        v-model="menuSelectedIndex"
        :disabled="integratorId == 'new'"
      >
      </LateralPanel>
      <v-container fluid pa-0 mt-1 mx-4 style="">
        <v-card class="ma-0">
          <CardInfo
            v-if="menuSelectedIndex == 0"
            :integrator="integrator"
            :integratorId="integratorId"
            @saved="saved"
          ></CardInfo>
          <CardUsers
            :integrator-id="integratorId"
            :integrator="integrator"
            v-if="menuSelectedIndex == 1"
          ></CardUsers>
          <CardStations
            v-if="menuSelectedIndex == 2"
            :integrator-id="integratorId"
          ></CardStations>
          <CardInverters
            v-if="menuSelectedIndex == 3"
            :integrator-id="integratorId"
          ></CardInverters>
          <!-- <CardUsers
            :integrator-id="integratorId"
            :integrator="integrator"
            v-if="menuSelectedIndex == 4"
            profile-id="4"
          ></CardUsers> -->

          <CardIntegrations
            :integrator-id="integratorId"
            :integrator="integrator"
            v-if="menuSelectedIndex == 4"
          ></CardIntegrations>
          <CardClients
            :integrator-id="integratorId"
            :integrator="integrator"
            v-if="menuSelectedIndex == 5"
          ></CardClients>
        </v-card>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import GlobalFunctions from "../../../../libraries/functions/globalFunctions";
import { mapState } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";
//import IntegratorManage from "../../components/integrator/IntegratorManage.vue";

import LateralPanel from "./LateralPanel.vue";
import CardInfo from "./CardInfo.vue";
import CardUsers from "./CardUsers.vue";
import CardStations from "./CardStations.vue";
import CardInverters from "./CardInverters.vue";
import CardClients from "./CardClients.vue";
import CardIntegrations from "./CardIntegrations.vue";

export default {
  name: "Integrator",
  props: ["integratorId"],
  components: {
    LateralPanel,
    CardInfo,
    CardUsers,
    CardStations,
    CardInverters,
    CardClients,
    CardIntegrations,
    //IntegratorManage,
  },

  data() {
    return {
      alert: true,
      test: "",
      menuSelectedIndex: 0,
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      integrator: {
        // id: "", //Auto increment
        createdAt: "",
        updatedAt: "",
        ownerId: "",
        creatorId: "",
        email: "",
        approverid: "",
        name: "",
        legalName: null, //Razão social,
        typeId: 1, //1-pj 2-pf
        rg: "",
        cnpj: null, //
        cpf: null,
        ie: "",
        phone: null,
        streetAddress: "",
        neighborhood: "",
        zipCode: "",
        cityId: "", //cityId
        approved: true, //bool  empresa.status,
        profilePhoto: "",
        relationshipId: "integrador",
        //svStatus :empresa.status ? empresa.status:false,
        sisId: null,
        ploomesId: null,
        svId: 0, //Id solarview
        svStatus: false, //status no solarview
        colorPrimary: "",
        colorSecondary: "",
        stations: [
          //stationIds
        ],
        inverters: [
          //inverterIds
        ],
        users: [
          //userIds , clients and users
        ],
      },
    };
  },
  methods: {
    async searchZipCode() {
      if (this.integrator.zipCode.length == 9) {
        let value = this.integrator.zipCode;
        let searchZipCode = await GlobalFunctions.searchZipCode(value);

        this.integrator.cityId = searchZipCode.cityName;
        this.integrator.stateId = searchZipCode.stateName;
        this.integrator.neighborhood = searchZipCode.neighborhood;
        this.integrator.streetAddress = searchZipCode.streetAddress;
      }
    },

    async saved(id) {
      // alert(id);
      this.integrator.id = id;
      this.$router.replace(`/integrator/${id}`);
    },
    // async saveIntegrator() {
    //   if (this.integratorId == "new") {
    //     var d = new Date();
    //     this.integrator.createdAt = d.getTime();
    //     this.integrator.updatedAt = d.getTime();
    //     this.integrator.ownerId = this.user.uid;
    //     this.integrator.creatorId = this.user.uid;
    //     this.integrator.approverid = this.user.uid;
    //     const ans = await corsolarApi.integratorsFunction.saveNewIntegrator(
    //       this.integrator,
    //       this.user
    //     );
    //     console.log(JSON.stringify({ ans }));
    //   }
    // },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  async mounted() {
    if (this.integratorId == "new") {
    } else {
      // this.integrator = await corsolarApi.integratorsFunction.getIntegratorById(
      //   this.integratorId
      // );
      const ans = await strapiApi.integrators.getById(this.integratorId);

      if (ans.data) {
        this.integrator = ans && ans.data && ans.data.attributes;
        this.integrator.id = ans && ans.data && ans.data.id;
      } else {
        alert(JSON.stringify(ans && ans.error));
      }
    }
  },
};
</script>
