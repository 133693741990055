import Vue from "vue";
import Router from "vue-router";

import Stations from "@/views/dashboard/pages/stations/Stations";
import Station from "@/views/dashboard/pages/stations/Station";
import managementUsers from "./views/dashboard/pages/stations/managementUsers";
import Account from "@/views/dashboard/pages/user/Account";
import Alarms from "@/views/dashboard/pages/alarms/Alarms";
import info from "./views/dashboard/pages/stations/info.vue";
import ImportXlsx from "./views/dashboard/pages/stations/importXlsx";
import WaitingPage from "./views/dashboard/pages/infoPages/waiting.vue";
import Integrations from "./views/dashboard/pages/integrations/Integrations.vue";
import Integrators from "./views/dashboard/pages/integrators/Integrators.vue";
import Integrator from "./views/dashboard/pages/integrators/Integrator.vue";
import store from "./store";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",

      meta: {
        title: "Login",
      },
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/views/dashboard/pages/login/Login.vue"
        ),
    },
    {
      path: "/register",
      name: "Register",

      meta: {
        title: "Register",
      },
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/views/dashboard/pages/login/Register.vue"
        ),
    },

    {
      path: "/resetPassword",
      name: "ResetPassword",

      meta: {
        title: "Register",
      },
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/views/dashboard/pages/login/ResetPassword.vue"
        ),
    },

    {
      path: "/accessDenied",
      name: "AccessDenied",

      meta: {
        title: "denied",
      },
      component: () =>
        import(
          /* webpackChunkName: "AcessDenied" */ "@/views/dashboard/pages/infoPages/accessDenied.vue"
        ),
    },
    {
      path: "/",
      redirect: { path: "/stations" },
      component: () => import("@/views/dashboard/Index"),

      children: [
        {
          name: "Stations",
          path: "/stations",
          component: Stations,
        },
        {
          name: "Station",
          path: "/station/:stationId",
          props: true,
          component: Station,
        },
        //Integrators
        {
          name: "Integrators",
          path: "/integrators",
          component: Integrators,
        },
        //Integrators
        {
          name: "Integrator",
          path: "/integrator/:integratorId",
          component: Integrator,
          props: true,
        },

        {
          name: "Alarmes",
          path: "/alarmes",
          component: Alarms,
        },
        {
          path: "/account",
          name: "Account",
          component: Account,
        },

        {
          name: "ImportXlsx",
          path: "/import",
          component: ImportXlsx,
        },
        {
          name: "WaitingPage",
          path: "/waiting",
          component: WaitingPage,
        },

        {
          name: "/#",
          path: "/:pathMatch(.*)*",
          component: Stations,
          //component: () => import("./views/dashboard/pages/infoPages/notFound"),
          // beforeEnter: async (to, from, next) => {
          //   const user = await store.state.auth.user;
          //   if (user) {
          //     next({ name: "Stations" });
          //   } else {
          //     next({ name: "Login" });
          //   }
          // },
        },
      ],
    },
  ],
});
