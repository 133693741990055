
var debug = false;
var GlobalFunctions = {
  //db = this.$firebase.firestore();

  name:"teste",
  optionsMoneyBr: {
    locale: "pt-BR",
    prefix: "R$",
    suffix: "",
    length: 11,
    precision: 2,
  },
  optionsDecimal: {
    locale: "pt-BR",
    prefix: "",
    suffix: "",
    length: 11,
    precision: 2,
  },
  optionsInteger: {
    locale: "pt-BR",
    prefix: "",
    suffix: "",
    precision: 0,
  },
  optionsPercent: {
    locale: "pt-BR",
    prefix: "",
    suffix: "%",
    length: 11,
    precision: 2,
  },
  formatDecimal(value,minimumFractionDigits ){
    var d =  Math.round(value*100)/100
     var r  = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: minimumFractionDigits?minimumFractionDigits:2  }).format(d || 0)

   return r;
  },

  
  async readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      //reader.readAsArrayBuffer(file);
      reader.readAsText(file);
    });
  },


  async searchZipCode(value){
    var cep = value.replace(/\D/g, '');
    if (cep != "") {
        //Make fetch to get al lthe informations
        let url = "https://viacep.com.br/ws/"+ cep +"/json";
        var info = await  fetch(url,{
        method: 'get',
        mode:"cors",    
        headers: { 'Content-Type': 'application/json' },
        })
            .then(res=>{
                console.log(res)
                return res.json();
            })
            .then(json=>{
                console.log(json)
                var infoCep = {
                                cityName: json.localidade + " - " + json.uf,
                                neighborhood : json.bairro,
                                streetAddress: json.logradouro
                };
              
                if(json.localidade == null)
                    infoCep = {
                                    cidade: "",
                                    bairro : "",
                                    rua: ""
                    };
                
                    return infoCep
            }) 
        };
        return info;
  },

    
}


export default function install (Vue){
  Object.defineProperty(Vue.prototype, '$globalFunctions', {
      get(){
          return GlobalFunctions
      }
  })
} 