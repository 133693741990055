<template>
  <v-card>
    <v-simple-table>
      <thead>
        <tr>
          <th>Status</th>
          <th>Fabricante</th>
          <th>Nome</th>
          <th>Cidade</th>
          <th>Potência atual</th>
          <th>Capacidade instalada</th>
          <th>Geração diária</th>
          <th>Geração mensal</th>
          <th>Geração total</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(station, indexStation) in stations" :key="station.id">
          <td v-if="stations[indexStation].state == 1">
            <v-icon color="green">mdi-check-circle</v-icon>
          </td>

          <td v-if="stations[indexStation].state == 2">
            <v-icon color="yellow">mdi-alert-circle</v-icon>
          </td>
          <td v-if="stations[indexStation].state == 3">
            <v-icon color="red">mdi-bell-circle</v-icon>
          </td>

          <td>{{ getManufacturerFromId(station.id) }}</td>
          <td>{{ station.stationName }}</td>
          <td>{{ station.regionStr }}</td>
          <td>
            {{ station.power }}
            {{ station.powerStr }}
          </td>
          <td>
            {{ station.capacity }}
            {{ station.capacityStr }}
          </td>
          <td>
            {{ station.dayEnergy }}
            {{ station.dayEnergyStr }}
          </td>
          <td>
            {{ station.monthEnergy }}
            {{ station.monthEnergyStr }}
          </td>
          <td>
            {{ station.allEnergy }}
            {{ station.allEnergyStr }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: "station-table",
  props: ["stations"],

  components: {},

  data() {
    return {
      stationData: [],
    };
  },
  methods: {
    getManufacturerFromId(id) {
      if (id && id.includes("solis")) return "SOLIS";
      if (id && id.includes("growatt")) return "GROWATT";
      return "";
    },
  },
};
</script>
