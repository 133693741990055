import functions from "./functions";
import store from "../../store";
var inverters = {
  //   async getAll(dateStart, dateEnd, encomendantes, exportadores) {
  //     console.log(JSON.stringify({ dateStart, dateEnd }));
  //     //inverters(filters:{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }} ]   }  ){

  //     var query = `query inverters{
  //         inverters( filters:{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }} ]   } ,pagination:{pageSize:1000}){
  //           data{
  //             attributes{
  //               DPE_NOM_FANTASIA_LIG
  //               PRI_DTA_ABERTURA
  //               transports{
  //                 data{
  //                   id
  //                    attributes{
  //                     ARMADOR

  //                     containers{
  //                       data{
  //                         id
  //                       }
  //                     }

  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }`;

  //     console.log(JSON.stringify({ query }));
  //     var graphql = JSON.stringify({
  //       query,
  //       variables: {},
  //     });
  //     //var url = "http://localhost:1337/graphql";
  //     var url = `${window.strapiUrl}/graphql`;
  //     console.log(JSON.stringify({ url }));
  //     var res = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${store?.state?.auth?.token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: graphql,
  //     })
  //       .then((res) => {
  //         console.log(res);
  //         return res.json();
  //       })
  //       .then((json) => {
  //         console.log(json);
  //         return json;
  //       });

  //     // if (res.data) {
  //     //   if (res.data.listarComponentes) {
  //     //     return res.data.listarComponentes;
  //     //   }
  //     // }
  //     return res;
  //   },

  async getWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    filterName,
    stationId
  ) {
    console.log(`sortDesc:${sortDesc}`);
    var textSort = sort ? `&sort[0]=${sort}${sortDesc ? ":desc" : ""}` : "";

    let textFilterName = "";

    if (filterName) {
      textFilterName = filterName
        ? `&filters[sn][$containsi]=${filterName}`
        : "";
    }

    if (stationId) textFilterName += `&filters[station][id]=${stationId}`;
    let url;
    if (pageSize && pageSize !== -1)
      url = `${process.env.VUE_APP_STRAPI_URL}/api/inverters?pagination[page]=${page}&pagination[pageSize]=${pageSize}${textFilterName}${textSort}`;
    else
      url = `${process.env.VUE_APP_STRAPI_URL}/api/inverters?pagination[page]=${page}&pagination[pageSize]=1000${textFilterName}${textSort}`;

    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    let values = functions.sanitize(res && res.data && res.data);

    const pagination = res.meta && res.meta.pagination;
    return { values, pagination };
  },

  async getById(inverterId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/inverters/${inverterId}`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const inverter = functions.sanitize(res && res.data);

    return inverter;
  },

  async post(inverter, user) {
    //console.log(JSON.stringify({ inverter, user }));
    //inverters(filters:{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }} ]   }  ){

    inverter.ownerId = user.email;
    inverter.createdId = user.email;

    if (inverter.cnpj) inverter.cnpj = inverter.cnpj.replace(/\D/g, "");

    if (inverter.cpj) inverter.cpj = inverter.cpj.replace(/\D/g, "");

    //console.log(inverter.cnpj);
    //console.log(JSON.stringify({ length: inverter.cnpj.length }));
    var body = JSON.stringify({
      data: inverter,
    });
    //var url = "http://localhost:1337/graphql";
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/inverters`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async new(inverter, manufacturerId) {
    var body = JSON.stringify({
      ...inverter,
    });
    //var url = "http://localhost:1337/graphql";
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/inverter/${manufacturerId}`;
    console.log(JSON.stringify({ url, body }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async getUsers(inverterId, profileId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=${profileId}&filters[$and][1][inverter][id][$eq]=${inverterId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[inverter][id][$eq]=${inverterId}&populate[0]=*`;

    console.log(JSON.stringify({ url }));

    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getClients(inverterId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=4&filters[$and][1][inverter][id][$eq]=${inverterId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[inverter][id][$eq]=${inverterId}&populate[0]=*`;

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getStations(inverterId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stations`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const stations = functions.sanitize(res && res.data && res.data);
    console.log(JSON.stringify({ stations }));

    return stations;
  },

  async put(inverter) {
    console.log("PUT" + JSON.stringify({ inverter }));

    //var url = "http://localhost:1337/graphql";
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/inverters/${inverter.id}`;
    delete inverter["id"];

    var body = JSON.stringify({
      data: inverter,
    });

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
};

export default inverters;
