var login = {
    async doLogin(email, password) {
        try{
            const res = await window.firebase
                .auth()
                .signInWithEmailAndPassword(email, password);
            return res;
        }catch(e){
            console.log(e)
            return e
        }


    },
    async getUser(uid) {
        const db = window.firebase.firestore();
        var ref = db.collection(window.path + "/corsolar/users");
        let objects = [];
        var ans = await ref
            .where("uid", "==", uid)
            .get()
            .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                objects.push(doc.data());
                // console.log(doc.id, " => ", doc.data());
        });

            return objects
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
            return error;
        });
        return ans
    },
    async sendEmail(email,nome){
        const reqbody = {
            name:nome,
            email:email
        }
        const sendInfo = JSON.stringify(reqbody)

        const request = await fetch('http://127.0.0.1:5001/corsolar-energy/us-central1/api/sendEmail', {
            method:'POST',
            headers:{
                "Content-type":"application/json"
            },
            body:sendInfo
        })
        const jsonRequest = request.json()

        return jsonRequest
    }
}
export default login;