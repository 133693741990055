<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row>
      <v-col
        cols="6"
        xs="6"
        sm="6"
        md="3"
        xl="3"
        v-for="(item, index) in data"
        :key="index"
      >
        <v-card class="mb-5" v-if="mobile">
          <v-row>
            <v-col cols="12" align="center" class="py-1">
              <v-icon class="primary--text">{{ item.icon }}</v-icon>
              <span class="text-h6 pl-1">{{ item.text }}</span>
            </v-col>
            <v-col align="center" class="pa-0">
              <span
                class="font-weight-bold text-h5 primary--text"
                v-decimal-format="item.value"
              >
                <span
                  class="grey--text text--darken-1 text-h5 font-weight-bold"
                  >{{ item.valueStr }}</span
                ></span
              >
            </v-col>
          </v-row>
        </v-card>

        <v-card class="pa-4" v-else>
          <v-row>
            <v-col cols="12">
              <div>
                <v-icon class="primary--text">{{ item.icon }}</v-icon>
                <span class="text-h5 pl-3">{{ item.text }}</span>
              </div>
            </v-col>
            <v-col cols="12" class="pl-5">
              <span
                class="font-weight-bold text-h2 primary--text"
                v-decimal-format="item?.value"
              ></span>
              <span class="grey--text text--darken-1 text-h3 font-weight-bold">
                {{ item?.valueStr }}</span
              >
            </v-col>

            <v-col cols="12">
              <div
                class="grey--text text--darken-1"
                v-if="item.additionalValue && !isNaN(item.additionalValue)"
              >
                <span>{{ item.additionalInfo }} </span>
                <span v-money-format="item.additionalValue"></span>
                <span class="font-weight-bold">
                  {{ item.additionalValueStr }}</span
                >
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-container class="ma-0" v-if="counts">
        <span>Resumo de usinas: </span>
        <span> {{ counts[1]?.count }} </span>
        <span style="color: green"> online,</span>
        {{ counts[2]?.count }} <span style="color: red"> offline,</span>
        {{ counts[3]?.count }} <span style="color: #f8b51e"> em alarme</span>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "card-info",
  props: ["data", "counts"],
  components: { Icon },
  data() {
    return {
      teste: null,

      headers: [
        { align: "start", value: `icon` },
        { value: "text" },
        { value: "value" },
        { value: "valueStr" },
        { value: "additionalInfo" },
        { value: "additionalValue" },
      ],
    };
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  mounted() {},
  created() {
    console.log(this.data);
  },
  watch: {},
  methods: {},
};
</script>
<style scoped>
#text {
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
}
</style>
