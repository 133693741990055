<template>
  <v-container>
    <!-- <v-dialog v-model="openDialog"> <p>Test</p> </v-dialog> -->
    <ModalInverter
      v-model="openDialog"
      :inverterId="inverterEditId"
      :integrator-id="integratorId"
      @update="update"
    ></ModalInverter>
    <v-row class="pa-4">
      <p>Inversores</p>
      <v-spacer></v-spacer>
      <v-btn class="primary" @click="openModalInverter('new')"> Novo+ </v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="inverters"
      item-key="id"
      class="custom-table"
    >
      <template v-slot:item.id="{ item }">
        <v-icon
          color="#08243c"
          @click="openModalInverter(item.id)"
          style="font-size: 30px"
          >mdi-magnify</v-icon
        >
      </template>
      <template v-slot:item.createdAt="{ item }">
        <span v-date-format="item.createdAt"></span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";

import ModalInverter from "../../components/modals/ModalInverter.vue";
export default {
  name: "Cardinverters",
  components: {
    ModalInverter,
  },
  props: ["integratorId"],
  data() {
    return {
      openDialog: false,
      inverters: [],
      inverterEditId: null,
      headers: [
        {
          text: "Ação",
          align: "start",
          sortable: false,
          value: "id",
          width: "20px",
        },
        {
          text: "Data de criação",
          value: "createdAt",
          width: "20px",
        },
        {
          text: "Serial number",
          value: "sn",
          width: "20px",
        },
        { text: "Forncedor", value: "manufacturerName", width: "100px" },
      ],
    };
  },
  async mounted() {
    this.inverters = await this.getinverters();
  },

  methods: {
    async update() {
      this.inverters = await strapiApi.integrators.getInverters(
        parseInt(this.integratorId)
      );

      this.openDialog = false;
    },
    async getinverters() {
      const inverters = await strapiApi.integrators.getInverters(
        parseInt(this.integratorId)
      );
      return inverters;
    },

    openModalInverter(inverterId) {
      this.inverterEditId = inverterId;
      this.openDialog = true;
    },
  },
};
</script>

<style>
.custom-table .v-data-table__wrapper {
  border: none;
}

.custom-table .v-data-table__thead > tr > th:not(:last-child),
.custom-table .v-data-table__tbody > tr > td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}
table td,
th {
  border-left: 1px solid #dddddd;
}
</style>
