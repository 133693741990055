// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/base";
//import "./plugins/chartist";
import "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import FirebaseVue from "@/libraries/firebase";
import VueSession from "vue-session";
import excel from "vue-excel-export";

import GlobalFunctions from "./libraries/functions/globalFunctionsVue";

import GlobalDirectives from "./globalDirectives";

import VuetifyMoney from "vuetify-money";
import VueMask from "v-mask";

// import DaoVue from '@/libraries/classes/DaoVue.js'
// Vue.use(DaoVue);
import VNumeric from "vuetify-numeric/vuetify-numeric.umd.min";
Vue.use(VNumeric);

import { VTextField } from "vuetify/lib"; //Globally import VTextField

Vue.component("v-text-field", VTextField);

//Function to enable access from corporate networks

// firebase.firestore().settings({ experimentalForceLongPolling: true });

import VueCurrencyInput from "vue-currency-input";
Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: false, // only override the default currency 'EUR' with 'USD'
    locale: "pt-BR",
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true,

    precision: 2,
    distractionFree: true,
  },
});

Vue.use(VuetifyMoney);
Vue.use(VueSession);
Vue.use(FirebaseVue);
Vue.use(excel);

Vue.use(GlobalDirectives);
Vue.use(GlobalFunctions);
// As a plugin

Vue.use(VueMask);

window.firebase = Vue.prototype.$firebase;

window.debug = process.env.NODE_ENV === "development" ? true : false;
window.debug = false;

//Vue.prototype.$dao.firebase =  Vue.prototype.$firebase;

const prod = true;

window.path = prod ? "/databases" : "/testes";

router.beforeEach(async (to, from, next) => {
  console.log("beforeEach");
  const user = await store?.state?.auth?.user;
  if (
    !user &&
    to.name != "Login" &&
    to.name != "Register" &&
    to.name != "ResetPassword"
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

//ResetPassword

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
