<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col align="center">
                <v-row>
                    <v-col cols="12"><span class="text-h1 font-weight-black">{{error}}</span></v-col>
                    <v-col cols="12">
                        <span class="text-h2">{{message}}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <span>{{info}}</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" align="center">
                <v-btn
                    class="actionButton"
                    @click="backToSquareOne"
                >
                Voltar para o Login
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
    name: "waiting-page",
    data() {
        return {
            error: "CADASTRADO!",
            message: "Agora é só aguardar.",
            info: "Nossa equipe está analisando seus dados e em breve liberará seu acesso."
        }
    },
    computed: {
    },
    methods:{
        ...mapMutations(['CLEAR_USER', 'CLEAR_TOKEN', 'CLEAR_ACCESS']),
        async backToSquareOne() {
            await this.$firebase.auth().signOut();
            this.CLEAR_USER()
            this.CLEAR_TOKEN()
            this.CLEAR_ACCESS()

            this.$router.push("/login");
        }
    }
}
</script>