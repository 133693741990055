<template>
  <v-container>
    <!-- <v-dialog v-model="openDialog"> <p>Test</p> </v-dialog> -->
    <ModalUser
      v-model="openDialog"
      :user-id="userEditId"
      :integrator="integrator"
      :integratorId="integratorId"
      @update="update"
    ></ModalUser>
    <v-row class="pa-4">
      <p>Usuários internos (tem acesso total)</p>
      <v-spacer></v-spacer>
      <v-btn class="primary" @click="openModalUser('new')"> Novo+ </v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="users"
      item-key="id"
      class="custom-table"
    >
      <template v-slot:item.id="{ item }">
        <v-icon
          color="#08243c"
          @click="openModalUser(item.id)"
          style="font-size: 30px"
          >mdi-magnify</v-icon
        >
      </template>
      <template v-slot:item.createdAt="{ item }">
        <span v-date-format="item.createdAt"></span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import ModalUser from "../../components/modals/ModalUser.vue";
export default {
  name: "CardUsers",
  components: {
    ModalUser,
  },
  watch: {
    profileId(val, oldVal) {
      if (val === oldVal) return;
      //alert(`changed user:${val}`);
      this.users = [];
      this.update();
    },
  },
  props: ["integratorId", "integrator", "profileId"],

  data() {
    return {
      openDialog: false,
      users: [],
      //   headers:[

      // ],

      userEditId: null,
      headers: [
        {
          text: "Ação",
          align: "start",
          sortable: false,
          value: "id",
          width: "20px",
        },
        {
          text: "Data de criação",
          value: "createdAt",
          width: "20px",
        },
        {
          text: "Nome",
          value: "username",
          width: "20px",
        },
        { text: "Email", value: "email", width: "100px" },
      ],
    };
  },
  async mounted() {
    this.users = await this.getUsers();
  },

  methods: {
    async update() {
      this.users = await this.getUsers(parseInt(this.integratorId));

      this.openDialog = false;
    },
    async getUsers() {
      const users = await strapiApi.integrators.getUsers(
        parseInt(this.integratorId),
        strapiApi.roleIds.integrator
      );

      this.openDialog = false;
      return users;
    },

    openModalUser(userId) {
      this.userEditId = userId;
      this.openDialog = true;
    },
  },
};
</script>

<style>
.custom-table .v-data-table__wrapper {
  border: none;
}

.custom-table .v-data-table__thead > tr > th:not(:last-child),
.custom-table .v-data-table__tbody > tr > td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}
table td,
th {
  border-left: 1px solid #dddddd;
}
</style>
