import firebase from "firebase/app";
import "firebase/firestore";

var userLogs = {
  async verifyIfExistLog(data, route) {
    if (!data) return;
    const db = window.firebase.firestore();
    if (route && route.currentRoute && route.currentRoute.fullPath) {
      route = route.currentRoute.fullPath;
      console.log(JSON.stringify({ route }));
      // start verification
      // const log = await this.getLog(data)
      // if( (log) ){
      //     await this.updateLog(log,route)
      // }else{
      //     await this.createLog(data)
      // }

      //create log
      const response = this.formatRoutes(route);
      await this.createLog(data, response);
    }
  },
  async createLog(data, response) {
    //alert("createLog");
    var d = new Date();

    const db = window.firebase.firestore();

    let log = {
      id: response.id + "_" + d.getTime() + "_" + data.email,
      createdAt: d.getTime(),
      userId: data.uid ? data.uid : data.email,
      userEmail: data.email,
      description: response.description,
      logId: response.id,
    };
    //firestore post userLog
    var ref = db.collection(window.path + "/corsolar/userLogs");
    var e = await ref
      .doc(log.id)
      .set(log)
      .then((doc) => {
        console.log("Document created successfully!" + JSON.stringify(doc));
        return doc;
      })
      .catch((error) => {
        console.error(error.message);
      });

    return e;
  },
  async getLog(data) {
    const db = window.firebase.firestore();
    var objects = [];
    var ref = db.collection(window.path + "/corsolar/userLogs");
    var e = await ref
      .where("userEmail", "==", data.email)
      .get()
      .then((querySnapshot) => {
        objects = querySnapshot.docs.map((x) => x.data());
      })
      .catch((error) => {
        console.error(error.message);
      });
    return objects[0];
  },
  async updateLog(log, route) {
    const logRoute = this.formatRoutes(route);
    log.logId.push(logRoute);
    console.log(log);

    const db = window.firebase.firestore();
    var ref = db.collection(window.path + "/corsolar/userLogs");
    var e = await ref
      .doc(log.userEmail)
      .update(log)
      .then((doc) => {
        console.log("Document updated successfully!" + JSON.stringify(doc));
        return doc;
      })
      .catch((error) => {
        console.error(error.message);
      });
    return e;
  },
  formatRoutes(route) {
    var response = {};
    if (route == "/login") {
      response.id = 1;
      response.description = "User login";
    } else if (route.match(/^\/station\//)) {
      response.id = 2;
      response.description = "User acessed station page";
    } else if (route.match(/\/account/)) {
      response.id = 3;
      response.description = "User acessed account page";
    } else if (route.match(/\/stations/)) {
      response.id = 4;
      response.description = "User acessed stations page";
    } else if (route.match(/\/alarmes/)) {
      response.id = 5;
      response.description = "User acessed alarms page";
    } else if (route.match(/\/managementUsers/)) {
      response.id = 6;
      response.description = "User acessed users management page";
    } else if (route == "/logout") {
      response.id = 7;
      response.description = "User logout";
    } else {
      response.id = 4;
      response.description = "User acessed stations page";
    }
    return response;
  },
};

export default userLogs;
