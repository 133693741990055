<template>
  <v-container fluid class="ma-0">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-autocomplete
          v-model="chips"
          :disabled="isUpdating || disabled"
          :items="items"
          :label="label"
          item-text="name"
          item-value="name"
          multiple
          class="ma-0 pa-0 custom-label"
          color="blue"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="remove(data.item)"
            >
              <span
                style="
                  border-left: 1px solid #dddddd;
                  color: black;
                  font-size: 14px !important;
                  font-weight: 500 !important;
                "
              >
                {{ data.item }}</span
              >
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title v-html="data.item"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AutocompleteSelect",
  props: ["label", "items", "value", "disabled"],
  data() {
    return {
      autoUpdate: true,
      isUpdating: false,
    };
  },

  computed: {
    chips: {
      get() {
        return this.value;
      },
      set(value) {
        // alert("emit");
        this.$emit("input", value);
        // this.$emit("update", value);
      },
    },
  },

  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },

  methods: {
    remove(item) {
      const index = this.chips.indexOf(item);
      if (index >= 0) this.chips.splice(index, 1);
      this.$emit("input", this.chips);

      //console.log(JSON.stringify(item));
      //this.$emit("remove", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-label {
  font-size: 20px;
}

// .v-autocomplete .label {
//   font-size: 20px;
// }

.v-select ::v-deep label {
  font-size: 18px;
  color: black;
}
</style>
//color: red; /* Add your desired label styles here */
