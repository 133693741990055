import store from "../../../store";

var refulog = {
  async auth(login, password, integratorId, providerId) {
    var body = {
      login,
      password,
      integratorId,
      providerId,
    };
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/integration/RefulogIntegration`;

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //let integration = res?.integration;

    // console.log("*****************RES****************");
    // console.log(JSON.stringify(integration));

    return res;
  },
};

export default refulog
