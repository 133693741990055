<template>
  <v-dialog v-model="showModal" width="600px">
    <v-card>
      <v-toolbar dark class="actionButton"
        >Integração - {{ provider?.name }}
        <v-spacer></v-spacer>
        <v-btn color="white darken-1" text @click="close"> X </v-btn>
      </v-toolbar>
      <!--
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="close"> Fechar </v-btn>
      </v-card-actions>
      <v-card-title> Integração </v-card-title> -->

      <div v-if="provider && provider.integration" class="px-6 mt-6">
        <v-card-text>
          <v-row>
            <v-text-field
              v-model="provider.integration.login"
              label="Login"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-model="provider.integration.password"
              label="Password"
              type="password"
            ></v-text-field>
          </v-row>
          <!-- <v-row>
          <v-text-field
            v-model="provider.integration.appId"
            label="APP ID"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
            v-model="provider.integration.appSecret"
            label="APP SECRET"
          ></v-text-field>
        </v-row> -->
        </v-card-text>

        <v-card-text>
          <v-row>
            <!-- <v-btn class="primary" @click="activate">activate</v-btn> -->
            <v-spacer></v-spacer>

            <v-btn class="primary" @click="activate" :disabled="loading"
              >Salvar
              <v-progress-circular
                v-show="loading"
                indeterminate
                color="primary"
              ></v-progress-circular
            ></v-btn>
          </v-row>
          <v-card-text>
            <v-alert
              type="error"
              icon="mdi-check"
              class="pa-2"
              v-model="errorAlert"
            >
              <v-row>
                <span> {{ errorMsg }}</span>
                <v-spacer></v-spacer>
                <span class="mr-5" @click="errorAlert = false">x</span>
              </v-row>
            </v-alert>
          </v-card-text>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import solarmanApi from "../../../../libraries/integrations/solarman/solarmanApi";
import strapiApi from "../../../../libraries/strapi/strapiApi";

export default {
  name: "modal-integration",
  props: ["value", "userId", "integratorId", "profileId", "provider"],
  data() {
    return {
      editingUser: {},
      errorAlert: false,
      errorMsg: "",
      loading: false,
    };
  },
  computed: {
    showModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    userId(val, oldVal) {
      if (val === oldVal) return;
      this.loadUser(val);
    },
  },

  methods: {
    close() {
      //this.showModal = true;
      this.$emit("input", false);
    },

    async activate() {
      this.loading = true;
      //  let hash = await solarmanApi.auth.getPasswordHash("Sup@4002");
      // alert(hash);
      // let login = "suportetecnicocorsolar@gmail.com";
      // let password = "Sup@4002";
      const { login, password } = this.provider.integration;
      let integratorId = this.integratorId;
      let providerId = this.provider.id;
      let ans;
      let integrationAns;

      if (this.provider.code == "solarman") {
        ans = await strapiApi.integrations.solarman.auth(
          login,
          password,
          integratorId,
          providerId
        );
        integrationAns = ans?.data;
      }

      if (this.provider.code == "growatt-oss") {
        ans = await strapiApi.integrations.growatt.auth(
          login,
          password,
          integratorId,
          providerId
        );
        integrationAns = ans?.integration;
      }

      if (this.provider.code == "growatt-server") {
        ans = await strapiApi.integrations.growattServer.auth(
          login,
          password,
          integratorId,
          providerId
        );
        integrationAns = ans?.integration;
      }

      if (this.provider.code == "soliscloud") {
        ans = await strapiApi.integrations.soliscloud.auth(
          login,
          password,
          integratorId,
          providerId
        );
        integrationAns = ans?.integration;
      }

      if(this.provider.code == 'refulog') {
        ans = await strapiApi.integrations.refulog.auth(
          login,
          password,
          integratorId,
          providerId
        )
        integrationAns = ans?.integration;
      }

      console.log(JSON.stringify({ ans }));

      if (ans.error) {
        //alert(JSON.stringify(ans));
        this.errorMsg = ans?.msg ? ans.msg : ans.error;
        this.errorAlert = true;
        this.loading = false;
        setTimeout(this.closeAlerts, 2000);
        //  this.showModal = false;
      } else {
        this.loading = false;
        this.$emit("update", { providerId, integration: integrationAns });
        //console.log(JSON.stringify(ans));
        this.showModal = false;
      }
    },
    closeAlerts() {
      this.errorAlert = false;
    },
    async save() {},

    async loadUser(userId) {},
  },
};
</script>

<style scoped></style>
