import functions from "./functions";
import roleIds from "./rolesIds";
import store from "../../store";
var alarms = {
  async getAll() {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/alarms`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const alarms = functions.sanitize(res && res.data && res.data);
    //console.log(JSON.stringify({ alarms }));

    return alarms;
  },

  async getWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    filterName,
    roleId,
    integratorId,
    userId,
    stationId
  ) {
    console.log(`sortDesc:${sortDesc}`);
    var textSort = sort ? `&sort[0]=${sort}${sortDesc ? ":desc" : ""}` : "";

    if (sort == "stationName") {
      textSort = `&sort[0]=inverter.station.name${sortDesc ? ":desc" : ":asc"}`;
    }
    if (sort == "inverterSn") {
      textSort = `&sort[0]=inverter.sn${sortDesc ? ":desc" : ":asc"}`;
    }

    let textFilterName;

    if (roleId == roleIds.engineer || roleId == roleIds.admin) {
      textFilterName = filterName
        ? `&filters[$or][0][message][$containsi]=${filterName}&filters[$or][1][inverter][sn][$containsi]=${filterName}&filters[$or][2][inverter][station][name][$containsi]=${filterName}`
        : "";
    }
    if (roleId == roleIds.integrator) {
      if (integratorId) {
        textFilterName = filterName
          ? `&filters[$and][0][$or][0][message][$containsi]=${filterName}&filters[$and][0][$or][1][inverter][sn][$containsi]=${filterName}&filters[$and][0][$or][2][inverter][station][name][$containsi]=${filterName}&filters[$and][1][inverter][station][integrators][id]=${integratorId}`
          : `&filters[inverter][station][integrators][id]=${integratorId}`;
      } else {
        textFilterName = filterName
          ? `&filters[$or][0][message][$containsi]=${filterName}&filters[$or][1][inverter][sn][$containsi]=${filterName}&filters[$or][2][inverter][station][name][$containsi]=${filterName}`
          : "";
      }
    }

    if (roleId == roleIds.client) {
      textFilterName = filterName
        ? `&filters[$and][0][$or][0][message][$containsi]=${filterName}&filters[$and][0][$or][1][inverter][sn][$containsi]=${filterName}&filters[$and][0][$or][2][inverter][station][name][$containsi]=${filterName}&filters[$and][1][inverter][station][users][id]=${userId}`
        : `&filters[inverter][station][users][id]=${userId}`;
    }

    console.log(JSON.stringify({ textFilterName }));
    if (stationId)
      textFilterName += `&filters[inverter][station][id]=${stationId}`;
    let url;
    if (pageSize && pageSize !== -1)
      url = `${process.env.VUE_APP_STRAPI_URL}/api/alarms?populate[0]=inverter&populate[inverter][populate][0]=station,manufacturer&pagination[page]=${page}&pagination[pageSize]=${pageSize}${textFilterName}${textSort}`;
    else
      url = `${process.env.VUE_APP_STRAPI_URL}/api/alarms?populate[0]=inverter&populate[inverter][populate][0]=station,manufacturer&pagination[page]=${page}&pagination[pageSize]=1000${textFilterName}${textSort}`;

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    let values = functions.sanitize(res && res.data && res.data);
    let values2 = values.map((el) => ({
      stationName: el?.inverter?.station?.name,
      inverterSn: el?.inverter?.sn,
      ...el,
    }));
    //console.log(JSON.stringify({ alarms }));
    const pagination = res.meta && res.meta.pagination;
    return { values: values2, pagination };
  },

  async getByUserId(userId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/alarms?populate[0]=inverter&populate[inverter][populate][0]=station,manufacturer&[sort][0]=startedAt:desc`;
    if (userId) {
      url += `&filters[users][id]=${userId}`;
    } else {
      return [];
    }
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    console.log(JSON.stringify({ res }));
    let alarms = functions.sanitize(res.data);
    console.log(JSON.stringify({ alarms }));
    return alarms;
  },

  async getById(alarmId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/alarms/${alarmId}?populate[0]=inverter&populate[inverter][populate][0]=station,manufacturer&[sort][0]=startedAt:desc`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    console.log(JSON.stringify({ res }));
    let alarm = functions.sanitize(res.data);
    console.log(JSON.stringify({ alarm }));
    return alarm;
  },

  async post(alarm, user) {
    //console.log(JSON.stringify({ alarm, user }));
    //alarms(filters:{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }} ]   }  ){

    alarm.ownerId = user.email;
    alarm.createdId = user.email;

    if (alarm.cnpj) alarm.cnpj = alarm.cnpj.replace(/\D/g, "");

    if (alarm.cpj) alarm.cpj = alarm.cpj.replace(/\D/g, "");

    //console.log(alarm.cnpj);
    //console.log(JSON.stringify({ length: alarm.cnpj.length }));
    var body = JSON.stringify({
      data: alarm,
    });
    //var url = "http://localhost:1337/graphql";
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/alarms`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async getUsers(alarmId, profileId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=${profileId}&filters[$and][1][alarm][id][$eq]=${alarmId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[alarm][id][$eq]=${alarmId}&populate[0]=*`;

    console.log(JSON.stringify({ url }));

    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getClients(alarmId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=4&filters[$and][1][alarm][id][$eq]=${alarmId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[alarm][id][$eq]=${alarmId}&populate[0]=*`;

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getalarms(alarmId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/alarms?filters[alarms][id][$eq]=${alarmId}`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const alarms = functions.sanitize(res && res.data && res.data);
    console.log(JSON.stringify({ alarms }));

    return alarms;
  },

  async getInverters(alarmId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/inverters?filters[alarms][id][$eq]=${alarmId}`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const inverters = functions.sanitize(res && res.data && res.data);
    console.log(JSON.stringify({ inverters }));

    return inverters;
  },

  async put(value) {
    console.log("PUT" + JSON.stringify({ alarm }));
    const alarm = JSON.parse(JSON.stringify(value));
    if (alarm.cnpj) alarm.cnpj = alarm.cnpj.replace(/\D/g, "");

    if (alarm.cpj) alarm.cpj = alarm.cpj.replace(/\D/g, "");

    //var url = "http://localhost:1337/graphql";
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/alarms/${alarm.id}`;
    delete alarm["id"];

    var body = JSON.stringify({
      data: alarm,
    });

    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  //   async getAll(dateStart, dateEnd, encomendantes, exportadores) {
  //     console.log(JSON.stringify({ dateStart, dateEnd }));
  //     //alarms(filters:{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }} ]   }  ){

  //     var query = `query alarms{
  //         alarms( filters:{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }} ]   } ,pagination:{pageSize:1000}){
  //           data{
  //             attributes{
  //               DPE_NOM_FANTASIA_LIG
  //               PRI_DTA_ABERTURA
  //               transports{
  //                 data{
  //                   id
  //                    attributes{
  //                     ARMADOR

  //                     containers{
  //                       data{
  //                         id
  //                       }
  //                     }

  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }`;

  //     console.log(JSON.stringify({ query }));
  //     var graphql = JSON.stringify({
  //       query,
  //       variables: {},
  //     });
  //     //var url = "http://localhost:1337/graphql";
  //     var url = `${window.strapiUrl}/graphql`;
  //     console.log(JSON.stringify({ url }));
  //     var res = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${store?.state?.auth?.token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: graphql,
  //     })
  //       .then((res) => {
  //         console.log(res);
  //         return res.json();
  //       })
  //       .then((json) => {
  //         console.log(json);
  //         return json;
  //       });

  //     // if (res.data) {
  //     //   if (res.data.listarComponentes) {
  //     //     return res.data.listarComponentes;
  //     //   }
  //     // }
  //     return res;
  //   },
};

export default alarms;
