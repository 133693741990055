var stations = {
  //siteS
  async patch(station) {
    if (window.debug) console.log("dao:savesite:");
    const db = window.firebase.firestore();
    var ref = db.collection(window.path + "/" + window.eid + "/stations");
    var e = await ref
      .doc(station.id)
      .set(site)
      .then((docRef) => {
        if (window.debug)
          console.log(
            "Document successfully updated!" + JSON.stringify(docRef)
          );
        return docRef;
      })

      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    return site;
  },

  async upsert(site) {
    // var d = JSON.parse(JSON.stringify(site));
    // if (d.id) {
    //   var a = await this.patch(d);
    //   return a;
    // }
    // if (window.debug) console.log(JSON.stringify(d));
    // const db = window.firebase.firestore();
    // var ref = db.collection(window.path + "/" + window.eid + "/sites");
    // var e = await ref
    //   .add(d)
    //   .then((docRef) => {
    //     if (window.debug) console.log("Document successfully created!");
    //     return docRef;
    //   })
    //   .catch((error) => {
    //     console.error("Error writing document: ", error);
    //   });
    // d.id = e.id;
    // var a = await this.patch(d);
    // return d;
  },

  async getAllUserStations(stationIdFromUser) {
    if (window.debug) console.log("0-will get sites");
    const db = window.firebase.firestore();
    let objects = [];
    var ref = db.collection(window.path + "/corsolar/stations");
    const ans = await ref
      .where("providerStationId", "in", stationIdFromUser)
      .get()

      .then((querySnapshot) => {
        objects = querySnapshot.docs.map((x) => x.data());
        return objects;
      })
      .catch((error) => {
        if (window.debug) console.log("Error getting documents: ", error);
        return error;
      });
    return ans;
  },
  async getAll() {
    if (window.debug) console.log("0-will get sites");
    const db = window.firebase.firestore();
    let objects = [];
    var ref = db.collection(window.path + "/corsolar/stations");
    const ans = await ref
      .get()

      .then((querySnapshot) => {
        objects = querySnapshot.docs.map((x) => x.data());
        return objects;
      })
      .catch((error) => {
        if (window.debug) console.log("Error getting documents: ", error);
        return error;
      });
    return ans;
  },

  async getFromId(stationId) {
    const db = window.firebase.firestore();
    let objects = [];
    var ans = await db
      .collection(window.path + "/corsolar/stations")
      .where("providerStationId", "==", stationId.toString())
      .get()
      .then((querySnapshot) => {
        objects = querySnapshot.docs.map((x) => x.data());
        return objects;
      })
      .catch((error) => {
        if (window.debug) console.log("Error getting documents: ", error);
        return error;
      });
    //alert(JSON.stringify(ans));
    if (ans.length > 0) return ans[0];
    else {
    }
  },
};

export default stations;
