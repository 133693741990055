<template>
  <v-toolbar dense class="rounded" v-if="mobile">
    <v-toolbar-title>
      <span class="font-weight-bold subtitle-2"
        >{{ station && station?.name }} </span
      ><span>({{ station?.manufacturer?.name?.toUpperCase() }})</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div class="ml-1 grey--text text--darken-1 caption">
      <v-icon class="red--text caption">mdi-map-marker</v-icon>
      {{ station?.countyStr }}
    </div>
    <div class=""></div>
  </v-toolbar>

  <v-toolbar dense class="rounded" v-else>
    <v-toolbar-title>
      <span class="font-weight-bold text-h4"
        >{{ station && station?.name }} ||</span
      ><span>({{ station?.manufacturer?.name?.toUpperCase() }})</span>
    </v-toolbar-title>
    <div class="ml-1 grey--text text--darken-1">
      <v-icon class="red--text">mdi-map-marker</v-icon>
      {{ station?.cityName }}
    </div>
    <v-spacer></v-spacer>
    <div class="">
      <v-icon class="mr-1 amber--text text-darken-1">mdi-weather-sunny</v-icon>
      <!-- <span class="mr-1 grey--text text--darken-1">{{
          this.site.condTxtD
        }}</span> -->
    </div>
    <div class=""></div>
  </v-toolbar>
</template>
<script>
export default {
  name: "menu-bar",
  props: {
    station: {},
    mobile: {},
  },
  data() {
    return {
      buttonName: "",
      pathName: "",
    };
  },
  computed: {},
  mounted() {
    const routerName = this.$router.history.current.name;
    if (routerName == "Personal_info") {
      this.buttonName = "Usina";
      this.pathName = "station";
    } else if (routerName == "Station") {
      this.buttonName = "Info";
      this.pathName = "info";
    }
  },
  methods: {
    // func de rota
    goToPage(stationId) {
      if (stationId) this.$router.push(`/${this.pathName}/${stationId}`);
    },
  },
};
</script>
