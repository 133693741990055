var alarms = {
    async getAll() {
        const db = window.firebase.firestore();
        let objects = [];
        var ref = db.collection(window.path + "/corsolar/alarms");
        const ans = await ref
        .get()
        .then( (querySnapshot) => {
            objects = querySnapshot.docs.map((x) => x.data());
            return objects; 
        })
        .catch((error) => {
            if (window.debug) console.log("Error getting alarms: ", error)
            return error;
        });
        return ans;
    },
    async getAllRaw(){
      const db = window.firebase.firestore()
      var ref = db.collection(window.path + "/corsolar/alarms");
      const ans = await ref.get()
      return ans
    },
    async getLimitedDocs(limit){
      let objects = []
      const db = window.firebase.firestore()
      var ref = db.collection(window.path + "/corsolar/alarms")
      const ans = await ref 
                    .limit(limit)
                    .get()
                    .then((querySnapshot)=>{
                      objects = querySnapshot.docs.map((x) => x.data());
                      return objects
                    })
                    .catch((error) => {
                      if (window.debug) console.log("Error getting alarms: ", error)
                      return error;
                    });
      return ans
    },
    async getAll() {
      const db = window.firebase.firestore();
      let objects = [];
      var ref = db.collection(window.path + "/corsolar/alarms");
      const ans = await ref
      .get()
      .then( (querySnapshot) => {
          objects = querySnapshot.docs.map((x) => x.data());
          return objects; 
      })
      .catch((error) => {
          if (window.debug) console.log("Error getting alarms: ", error)
          return error;
      });
      return ans;
    },
    async getAllUserAlarms(siteIdFromUser) {
        if (window.debug) console.log("0-will get sites");
        const db = window.firebase.firestore();
        let objects = [];
        var ref = db.collection(window.path + "/corsolar/alarms");
        const ans = await ref
          .where("stationId", "==", siteIdFromUser)
          .get()
    
          .then((querySnapshot) => {
            objects = querySnapshot.docs.map((x) => x.data());
            return objects;
          })
          .catch((error) => {
            if (window.debug) console.log("Error getting documents: ", error);
            return error;
          });
        return ans;
    },
    async getStationByName(stationName){
      const db = window.firebase.firestore();
      let objects = []
      var ref = db.collection(window.path + "/corsolar/stations");
      const ans = await ref
                        .where("stationName", "==", stationName.toString())
                        .get()
                        .then((querySnapshot)=>{
                          objects = querySnapshot.docs.map((x)=>x.data());
                          console.log(objects)
                          return objects
                        })
      return ans
    },
    async getAlarmById(stationId){
      const db = window.firebase.firestore();
      let objects = []
      var ref = db.collection(window.path + "/corsolar/alarms");
      const ans = await ref
                        .where("stationId", "==", stationId.toString())
                        .get()
                        .then((querySnapshot)=>{
                          objects = querySnapshot.docs.map((x)=>x.data());
                          return objects
                        })
      return ans
    },
    async getAlarmBySN(stationSN){
      const db = window.firebase.firestore();
      let objects = []
      var ref = db.collection(window.path + "/corsolar/alarms");
      const ans = await ref
                        .where("alarmDeviceSn", "==", stationSN.toString())
                        .get()
                        .then((querySnapshot)=>{
                          objects = querySnapshot.docs.map((x)=>x.data());
                          return objects
                        })
      return ans
    },
    switchStateAlarme(value){
        switch(value){
          case "0":
            return 'Pendente'
            
          case "1":
            return 'Processado'
            
          case "2":
            return 'Restaurado'
            
        }
    },
    switchLevelAlarme(value){
        switch(value){
          case '1':
            return 'Atenção'
            
          case '2':
            return 'Comum'
            
          case '3':
            return 'Emergência'
            
        }
    },
    async getNextAlarms(page){
      if (window.debug) console.log("0-will get sites");
        const db = window.firebase.firestore();
        let objects = [];
        var ref = db.collection(window.path + "/corsolar/alarms")
                .orderBy('stationId')
                .limit(10)
        const ans = await ref
          .get()
          .then((querySnapshot) => {
            objects = querySnapshot.docs.map((x) => x.data());
            return objects;
          })
          .catch((error) => {
            if (window.debug) console.log("Error getting documents: ", error);
            return error;
          });
        return ans;
    },
    formatAlarmMsg(value){
      switch(value){
        case "NO-Grid":
          return 'Sem tensão de rede'
        case "Grid Over Voltage":
          return 'Sobretensão da rede'
        case "GRID-INTF.":
          return 'Interferência na rede'
        case "Grid Under Voltage":
          return "Subtensão da rede"
        case "Grid Over Frequency":
          return "Sobrefrequência da rede"
        case "Grid Under Frequency":
          return "Subfrequência da rede"
        case "OV-G-V01":
          return "Sobretensão na rede CA"
        case "OV-G-V02":
          return "Sobretensão na rede CA"
        case "G-IMP":
          return "Rede com alta impedância"
        case "OV-DC01":
          return "Sobretensão CC"
        case "OV-DC02":
          return "Sobretensão CC"
        case "OV-DC03":
          return "Sobretensão CC"
        case "OV-DC04":
          return "Sobretensão CC"
        case "OV-BUS":
          return "Sobretensão no barramento CC"
        case "UN-BUS01":
          return "Subtensão no barramento CC"
        case "UN-BUS02":
          return "Subtensão no barramento CC"
        case "GRID-INTF01":
          return "Interferência da rede CA"
        case "GRID-INTF02":
          return "Interferência da rede CA"
        case "OV-G-I":
          return "Sobrecorrente na rede CA"
        case "IGBT-OV-I":
          return " Sobrecorrente no IGBT"
        case "DC-INT OV-DCA-I":
          return "Sobrecorrente na entrada CC"
        case "IGFOL-F":
          return "Falha no rastreamento da rede atual"
        case "IG-AD":
          return "Falha na amostragem da corrente de rede"
        case "OV-TEM":
          return "Temperatura Excessiva"
        case "INI-FAULT":
          return "Falha na inicialização do sistema"
        case "DSP-B-Com-Fau":
          return "Falha de comunicação entre DPS principal e escravo"
        case "12Power-FAULT":
          return "Falha na fonte de alimentação CC"
        case "ILeak-Check":
          return 'Corrente de fuga'
        case "Relay-FAULT":
          return 'Falha na verificação do relé'
        case "DCInj-FAULT":
          return 'Injeção de corrente CC na rede CA'
        case "AFCI-Check":
          return 'Falha na detecção automática do módulo AFCI'
        case "AFCI":
          return 'Arco elétrico detectado no circuito CC'
        case "PV ISO PRO 01":
          return "Inversor detectar baixa resistência de isolamento no polo + ou -"
        case "PV ISO PRO 02":
          return "Inversor detectar baixa resistência de isolamento no polo + ou -"
        case "REVE-DC":
          return 'Uma das strings está ligada com polaridade incorreta ou um número diferente de módulos está ligado asentradas de um mppt'
        case "Reverse-DC":
            return 'Uma das strings está ligada com polaridade incorreta ou um número diferente de módulos está ligado asentradas de um mppt'
        case "PV-MID-ISO":
          return 'O inversor deteta baixa resistência de isolamento CC entre os conectos MC4 dos módulos'
          
      }
    },
    formatAlarmAdvice(value){
      // console.log('teste')
      if(value.state == 'Restaurado'){
        return value.advice = 'Nenhuma medida necessária'
      }
      if(value.state == "Pendente"){
          if(value.alarmCode == '1015'){
            return value.advice = '• Verifique as ligações e a chave/disjuntor no lado CA • Medir atensão da rede nos terminais do inversor'
          }else if (value.alarmCode == '1011'){
            return value.advice = 'Ajustar os limites de tensão e frequência caso seja permitido pela concessionária'
          }else if(value.alarmCode == '1030' || value.alarmCode == '1018' || value.alarmCode == '1048'){
            return value.advice = '• Reinicie o inversor • Mudar a placa de potência'
          }else if(value.alarmCode == '1019' || value.alarmCode == '1047'){
            return value.advice = 'Reinicie o inversor ou entre em contato com o instalador'
          }else if(value.alarmCode == '1032'){
            return value.advice == 'Verifique a ventilação ao redor do inversor ou se existe luz solar diretamente no equipamento durante o período de 15 minutos'
          }else if(value.alarmCode == '1036' || value.alarmCode == '1038' || value.alarmCode == '1031'){
            return value.advice == 'Reinicie o inversor ou entre em contato com o instalador'
          }else if(value.alarmCode == '1039'){
            return value.advice == '• Verifique as ligações CA e CC • Verifique as ligações dos cabos dentro do inversor'
          }else if(value.alarmCode == '1035' || value.alarmCode == '1037' || value.alarmCode == '1040'){
            return 'Reinicie o inversor ou entre em contato com o instalador'
          }else if(value.alarmCode == '1033'){
            return ' Reinicie o inversor • Observe as condições meteriológicas quando o alarme ocorrer • Meça as resistência de isolamento •Se a resistência de isolamento estiver normal, meça no mesmoclima do alame • Verifique fisicamente os cabo'
          }else if(value.alarmCode == '1028'){
            return '•Verifique a polaridade das strings, se houver strings conectadas inversamente, aguarde até a noite onde o períodode irradiância é baixo e a corrente das string é menor que 0,5A.Desligue os dois polos da string e corrija o problema depolaridade • Se a polaridade das strings estiver correta,verifique se todas as strings possuem o mesmo número demódulo. Caso contrario, modifique a configuração do sistema'
          }else if(value.alarmCode == '1029'){
            return '• Reinicie o inversor • Verifique fisicamente todos os conectores MC4, interruptor CC externo, fusíveis'
          }
          
      }
    }
}

export default alarms