import functions from "./functions";
import store from "../../store";
var providers = {
  async getAll() {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/providers?populate=*&filters[active]=true`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/providers?populate=*`;
    console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store?.state?.auth?.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    let providers = functions.sanitize(res && res.data && res.data);
    //console.log(JSON.stringify({ stations }));
    providers.sort((a, b) => a?.id - b?.id);
    return providers;
  },
};

export default providers;
