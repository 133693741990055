<template>
  <div>
    <v-chip v-if="statusId == 1" variant="text" color="#7d751b">
      <span style="color: white">Pendente</span>
    </v-chip>
    <v-chip v-if="statusId == 2" variant="text" color="#824621">
      <span style="color: white">Processado</span>
    </v-chip>
    <v-chip v-if="statusId == 3" variant="text" color="#4b943e">
      <span style="color: white">Restaurado</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "StatusChip",
  props: ["statusId"],
};
</script>
