<template>
    <v-container>
        <!-- Alert -->
        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            shaped
            absolute
            color="green"
        >
            {{snackbarText}}
            <template v-slot:action="{ attrs }" >
                <v-btn
                    text
                    small
                    v-bind="attrs"
                    @click="closeSnackbar"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <!-- isso é de uma biblioteca chamada vuetify-xlsx-csv-parser a função dela é ler um arquivo xlsx -->
        <vuetifyXlsxCsvParserSsr
            :columns="columns"
            @results="processResults"
            placeholder="Selecione o arquivo aqui:"
        ></vuetifyXlsxCsvParserSsr>
        <v-divider></v-divider>
        <!-- ao ler o arquivo, se houver resultado, ele chama essa tabela onde serão associado as colunas do xlsx com a dessa tabela -->
        <div v-if="results && results.length">
            <v-simple-table fixed-header height="400px">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th v-for="col in columns" :key="col.key">
                                {{col.label}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in results" :key="index">
                            <td v-for="col in columns" :key="col.key">
                                {{row[col.key]}}
                            </td>
                        </tr>
                    </tbody>

                </template>
            </v-simple-table>
        </div>
        <v-row justify="end" class="mt-3">
            <v-col xs="6" sm="3" md="2" xl="2">
                <v-btn 
                    @click="update"
                    block
                    :loading="loading"
                    :disabled="loading"
                    class="actionButton"
                >
                    Atualizar Banco
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import vuetifyXlsxCsvParserSsr from 'vuetify-xlsx-csv-parser';
import corsolarApi from '../../../../libraries/corsolar/corsolarApi';
export default {
    components: {
        vuetifyXlsxCsvParserSsr
    },
    data() {
        return {
            loading: false,
            snackbarText: 'Banco atualizado!',
            snackbar: false,
            timeout: 5000,
            invertersInDataBase: null,
            invertersToStations: [],
            columns: [
                {
                    key: 'plantName',
                    label: 'Plant Name',
                },
                {
                    key: 'time',
                    label: 'Time',
                },
                {
                    key: 'country',
                    label: 'Country'
                },
                {
                    key: 'region',
                    label: 'Region'
                },
                {
                    key: 'city',
                    label: 'City'
                },
                {
                    key: 'county',
                    label: 'County'
                },
                {
                    key: 'plantAdress',
                    label: 'Plant Address'
                },
                {
                    key: 'owner',
                    label: 'Owner'
                },
                {
                    key: 'plantContact',
                    label: 'Plant Contact'
                },
                {
                    key: 'inverterSn',
                    label: 'Inverter SN'
                },
            ],
            results: null,
            hint: "Arquivo da solis"
        }
    },
    async mounted() {
        //aqui é chamada a func getAll para pegar todos os inversores e armazenar nessa variavel
        //no documento desses inversores tem a identificação da estação na qual ele pertence
        this.invertersInDataBase = await corsolarApi.inverters.getAll();

    },
    methods: {
        processResults(results) {
            this.results = results
        },
        async update() {
            // esta função é responsável por pegar os campos da tabela que tem valores e jogar para esse obj
            //é feita uma comparação entre a tabela do xlsx e os inversores no data base, onde o serial number bater com o serial number do xlsx eu pego o id da estação
            //com o id da estação é feito uma atualização dos dados da estação
            this.invertersToStations = []
            this.loading = true
            var obj = {}
            
            for (let a = 0; a < this.results.length; a++) {
                const result = this.results[a];
                if(result.inverterSn){
                    if(result.inverterSn.match(/\,/)){
                        result.inverterSn = result.inverterSn.split(',');
                        console.log(`Station Name: ${result.plantName} with array of inverters, updating`);
                        await this.updateWithMoreInversors(result)
                    }else{
                        let found = this.invertersInDataBase.find(element => element.sn == result.inverterSn)
                        console.log(`Station Name: ${result.plantName} with one inverter, updating`);
                        if(found && found.sn == result.inverterSn){
                            obj.sn = result.inverterSn
                            obj.plantName = result.plantName
                            obj.addr = result.plantAdress
                            obj.owner = result.owner
                            var id = found.stationId

                            await corsolarApi.updateStations.insertingUserData(obj, id)
                        }
                        obj = {}
                    }
                }
            }
            this.snackbar = true
            this.loading = false
        },
        async updateWithMoreInversors(result){
            this.invertersToStations = []
            this.loading = true
            var obj = {}
            result.inverterSn.forEach(async (inverter) => {
                let found = this.invertersInDataBase.find(element => element.sn == inverter)
                if(found && found.sn == inverter){
                    obj.sn = result.inverterSn
                    obj.plantName = result.plantName
                    obj.addr = result.plantAdress
                    obj.owner = result.owner
                    var id = found.stationId

                    await corsolarApi.updateStations.insertingUserData(obj, id)
                }
                obj = {}
            })
            
        },
        closeSnackbar() {
            this.snackbar = false
        } 
    }
}
</script>