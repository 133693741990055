<template>
  <v-container fluid>
    <Menu :station="station" :mobile="mobile"></Menu>

    <v-card class="elevation-5" v-if="mobile">
      <v-row>
        <v-col cols="12" sm="6" md="6" xl="6">
          <v-list>
            <v-subheader class="font-weight-bold"
              >Informações da estação</v-subheader
            >
            <v-list-item
              v-for="station in stationData"
              :key="station.text"
              class="ml-1"
            >
              <v-list-action>
                <v-subheader class="caption">{{ station.title }}</v-subheader>
              </v-list-action>
              <v-list-item-content class="caption">{{
                station.text
              }}</v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <v-subheader class="font-weight-bold">Conta Associada</v-subheader>
            <v-list-item v-for="user in userData" :key="user.text" class="ml-1">
              <v-list-action>
                <v-subheader class="caption">{{ user.label }}</v-subheader>
              </v-list-action>
              <v-list-item-content class="caption">{{
                user.text
              }}</v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <v-subheader class="font-weight-bold">Mais informações</v-subheader>
            <v-list-item v-for="info in moreInfo" :key="info.text" class="ml-1">
              <v-list-action>
                <v-subheader class="caption">{{ info.label }}</v-subheader>
              </v-list-action>
              <v-list-item-content class="caption">{{
                info.text
              }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="elevation-5" v-else>
      <v-row>
        <v-col cols="" xs="12" sm="6" md="6" xl="6">
          <v-list>
            <v-subheader class="font-weight-bold"
              >Informações da estação</v-subheader
            >
            <v-list-item
              v-for="station in stationData"
              :key="station.text"
              class="ml-4"
            >
              <v-list-action>
                <v-subheader>{{ station.title }}</v-subheader>
              </v-list-action>
              <v-list-item-content>{{ station.text }}</v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <v-subheader class="font-weight-bold">Conta Associada</v-subheader>
            <v-list-item v-for="user in userData" :key="user.text" class="ml-4">
              <v-list-action>
                <v-subheader>{{ user.label }}</v-subheader>
              </v-list-action>
              <v-list-item-content>{{ user.text }}</v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list>
            <v-subheader class="font-weight-bold">Mais informações</v-subheader>
            <v-list-item v-for="info in moreInfo" :key="info.text" class="ml-4">
              <v-list-action>
                <v-subheader>{{ info.label }}</v-subheader>
              </v-list-action>
              <v-list-item-content>{{ info.text }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="" xs="12" sm="6" md="6" xl="6">
          <v-row class="mx-3" justify="center">
            <v-col cols="6" align="center">
              <v-responsive>
                <v-img :src="this.station.pic1Url"></v-img>
              </v-responsive>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import Menu from "../../components/core/menu.vue";
export default {
  name: "infoPage",
  props: ["stationId"],
  components: { Menu },
  data() {
    return {
      station: {},
      stationData: [],
      stationDataWithAlarm: [],
      userData: [],
      moreInfo: [],
    };
  },
  async mounted() {
    //puxa as informações do station a partir do ID que é passado para essa página através das props.
    this.station = await corsolarApi.stations.getFromId(this.stationId);
    // transformar a data vindo da API da solis, ele vem com o fuso chines, então temos que utilizar o toLocaleString
    function transformDate(value) {
      var date = new Date(value);
      let formatter = date.toLocaleString("zh-cn", {
        timeZone: "Asia/Shanghai",
      });
      var newDate = new Date(formatter);

      return newDate.toLocaleString();
    }
    //função para validar o estado do alare
    function switchStateAlarme(value) {
      switch (value) {
        case 0:
          return "Pendente";
        case 1:
          return "Resolvido";
        case 2:
          return "Resolvendo";
      }
    }
    // função para descobrir a duração do alarme em dias
    function diasDiff(value) {
      const inicioAlarme = value;
      var d1 = new Date(inicioAlarme).toISOString().split("T")[0];
      var d2 = new Date().toISOString().split("T")[0];

      const diffInMs = new Date(d2) - new Date(d1);
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      return diffInDays;
    }
    function switchLevelAlarme(value) {
      switch (value) {
        case "1":
          return "Atenção";
        case "2":
          return "Comum";
        case "3":
          return "Emergência";
      }
    }

    this.stationData = await [
      {
        title: "Nome da estação: ",
        text: `${this.station.stationName}`,
      },
      {
        title: "Capacidade total dos Componentes: ",
        text: `${this.station.capacity} ${this.station.capacityStr}`,
      },
      {
        title: "Fuso-horário: ",
        text: `${this.station.timeZoneName}`,
      },
      {
        title: "Unidade monetária: ",
        text: `${this.station.money}`,
      },
      {
        title: "Ganho por kWh: ",
        text: `${this.station.price} ${this.station.money}/${this.station.dayEnergyStr}`,
      },
      {
        title: "Número do serial: ",
        text: this.station.sn,
      },
      {
        title: "Endereço da estação: ",
        text: this.station.addr,
      },
    ];

    this.userData = await [
      {
        label: "Proprietário: ",
        text: this.station.owner,
      },
      {
        label: "E-mail associado: ",
        text: this.station.email,
      },
    ];
    this.moreInfo = await [
      {
        label: "Instalador: ",
        text: `${this.station.installer}`,
      },
      {
        label: "Telefone: ",
        text: this.station.mobile,
      },
    ];
    // Aqui, se a estação estiver com o State = 3 significa que a estação está com o alarme ativado
    // com o alarme ativado, é inserido novas informações sobre a estação como, mensagem do alarm, data de inicio, duração, estado e etc..
    if (this.station.state == 3) {
      this.stationData.push(
        {
          title: "Mensagem do alarme",
          text: this.station.alarmMsg,
        },
        {
          title: "Data de Inicio do alarme",
          text: transformDate(this.station.alarmBeginTime),
        },
        {
          title: "Duração do Alarme",
          text: diasDiff(this.station.alarmBeginTime) + " dias",
        },
        {
          title: "Estado do Alarme",
          text: switchStateAlarme(this.station.alarmState),
        },
        {
          title: "Nivel do Alarme",
          text: switchLevelAlarme(this.station.alarmLevel),
        }
      );
    }
  },
  methods: {},
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
};
</script>
