import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";
import "firebase/firestore";

export const firebaseApp = firebase.initializeApp({
  apiKey:
    process.env.VUE_APP_ENV == "PROD"
      ? process.env.VUE_APP_API_KEY
      : process.env.VUE_APP_HOMOLOG_API_KEY,
  authDomain:
    process.env.VUE_APP_ENV == "PROD"
      ? process.env.VUE_APP_AUTH_DOMAIN
      : process.env.VUE_APP_HOMOLOG_AUTH_DOMAIN,
  databaseURL:
    process.env.VUE_APP_ENV == "PROD"
      ? process.env.VUE_APP_DATABASE_URL
      : process.env.VUE_APP_HOMOLOG_DATABASE_URL,
  projectId:
    process.env.VUE_APP_ENV == "PROD"
      ? process.env.VUE_APP_PROJECT_ID
      : process.env.VUE_APP_HOMOLOG_PROJECT_ID,
  storageBucket:
    process.env.VUE_APP_ENV == "PROD"
      ? process.env.VUE_APP_STORAGE_BUCKET
      : process.env.VUE_APP_HOMOLOG_STORAGE_BUCKET,
  messagingSenderId:
    process.env.VUE_APP_ENV == "PROD"
      ? process.env.VUE_APP_MESSAGING_SENDER_ID
      : process.env.VUE_APP_HOMOLOG_MESSAGING_SENDER_ID,
  appId:
    process.env.VUE_APP_ENV == "PROD"
      ? process.env.VUE_APP_API_ID
      : process.env.VUE_APP_HOMOLOG_API_ID,
  measurementId:
    process.env.VUE_APP_ENV == "PROD"
      ? process.env.VUE_APP_MEASUREMENT_ID
      : process.env.VUE_APP_HOMOLOG_MEASUREMENT_ID,
});

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, "$firebase", {
    get() {
      return firebaseApp;
    },
  });
}
