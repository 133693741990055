<template>
  <v-container>
    <v-container>
      <h1>Integradores</h1>
      <!-- <v-row>
        <v-spacer></v-spacer>
        <v-btn color="actionButton" @click="newIntegrator()">
          Novo <v-icon right>mdi-account-multiple-plus</v-icon>
        </v-btn>
      </v-row> -->
    </v-container>
    <v-container>
      <v-container>
        <v-row class="ma-2">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @blur="doSearch"
            @keydown.enter="doSearch"
          ></v-text-field>
          <!-- <v-btn @click="doSearch" color="primary">Buscar</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn class="mr-0" color="actionButton" @click="newIntegrator()">
            Novo <v-icon right>mdi-account-multiple-plus</v-icon>
          </v-btn>
        </v-row> </v-container
      ><v-card>
        <!-- <v-data-table :headers="headers" :items="integrators" :search="search">
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editIntegrator(item.id)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table> -->

        <v-data-table
          :headers="headers"
          :items="integrators"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length.sync="serverItemsLength"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          @update:items-per-page="updateRowsPerPage"
          @update:page="updatePage"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editIntegrator(item.id)"
              style="font-size: 30px"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
      <!-- <v-container>
        <v-row>
          <v-spacer></v-spacer>
          <v-select
            style="max-width: 100px"
            :items="itemsRowsPerPage"
            v-model="rowsPerPage"
          ></v-select>
        </v-row>
      </v-container> -->
    </v-container>
  </v-container>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
export default {
  name: "Integrators",
  data() {
    return {
      sortBy: "name",
      sortDesc: false,
      search: null,
      page: 1,
      itemsPerPage: 5,
      serverItemsLength: 15,
      menuSelectedIndex: 0,
      headers: [
        { text: "Editar", value: "actions", width: "20px", sortable: false },
        { text: "Nome", value: "name", align: "start" },
        { text: "CNPJ", value: "cnpj" },
        { text: "Cidade", value: "cityId" },
        { text: "Staus", value: "status" },
        // { text: "Ação", value: "action" },
      ],

      integrators: [],
    };
  },

  methods: {
    newIntegrator() {
      this.$router.push("/integrator/new");
    },
    editIntegrator(integratorId) {
      this.$router.push(`/integrator/${integratorId}`);
    },

    async updateSortBy() {
      //alert("updateSortBy");
      await this.doSearch();
    },

    async updateSortDesc() {
      //alert("updateSortDesc");
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },

    async doSearch() {
      const { values, pagination } =
        await strapiApi.integrators.getWithPagination(
          this.page,
          this.itemsPerPage,
          this.sortBy,
          this.sortDesc,
          this.search
        );

      //alert(JSON.stringify({ values, pagination }));
      this.integrators = values;
      this.serverItemsLength = pagination && pagination.total;
    },
  },
  async mounted() {
    await this.doSearch();
    // const { values, pagination } =
    //   await strapiApi.integrators.getWithPagination(
    //     this.page,
    //     this.itemsPerPage
    //   );

    // alert(JSON.stringify({ values, pagination }));
    // this.integrators = values;
    // this.serverItemsLength = pagination && pagination.total;
  },
};
</script>
